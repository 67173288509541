import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  currentSection = this.apiService.getSection(this.activatedRoute.snapshot.params.sectionId);
  currentProject = this.apiService.getProject(this.activatedRoute.snapshot.params.sectionId, this.activatedRoute.snapshot.params.projectId);

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  projectActions(type: string, content: string) {
    switch(type) {
      case 'external_url':
        window.open(content, '_blank');
        break;
      default:
    }
  }
    
}
