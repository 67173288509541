<div class="navbar" fxLayout="row" fxLayoutAlign="center">

  <div class="content" fxLayout="row" fxLayoutAlign="center center" *ngIf="navType === 0">
    <app-toggle [options]="sections" fxFlex></app-toggle>
  </div>

  <div class="content" fxLayout="row" fxLayoutAlign="start center" *ngIf="navType === 1">
    <app-button [name]="'← '+currentSection.title" (click)="goBack()" fxHide.xs></app-button>
    <app-button [name]="'←'" (click)="goBack()" fxHide fxShow.xs></app-button>
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <span class="title">{{currentProject.title}}</span>
    </div>
    <span fxFlex="93px" fxHide.xs></span>
    <span fxFlex="45px" fxHide fxShow.xs></span>
  </div>

  <div class="content" fxLayout="row" fxLayoutAlign="start center" *ngIf="navType === 2">
    <app-button [name]="'← Home'" (click)="goBack()" fxHide.xs></app-button>
    <app-button [name]="'←'" (click)="goBack()" fxHide fxShow.xs></app-button>
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <span class="title">{{currentSection.title}}</span>
    </div>
    <span fxFlex="93px" fxHide.xs></span>
    <span fxFlex="45px" fxHide fxShow.xs></span>
  </div>
  
</div>