import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() options: Array<String>;

  constructor(private viewportScroller: ViewportScroller) { }

  onClickScroll(elementId: string): void {
    this.viewportScroller.setOffset([0, 148]);
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit(): void {
  }

}
