import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  sections = [
    {
      id: 'about',
      title: 'About',
      type: 'hero',
      cta: '',
      message: {
        title: "Hi, I'm Arian,",
        description: "Product designer and software developer, leader of the Human-AI Interaction team  at Oracle. I design and help other people to design products and components that integrate AI."
      },
      projects: []
    },
    {
      id: 'work',
      title: 'Work',
      type: 'projects',
      cta: 'See more work',
      message: {
        title: '',
        description: ''
      },
      projects: [
        {
          id: 'osabor',
          title: 'O’Sabor experience improvements',
          thumbnail: 'osabor-thumbnail',
          hero: 'osabor-hero',
          date: '2017',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            }
          ],
          company: {
            name: 'FEMSA',
            url: 'http://www.femsa.com/en/'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "What is the O'Sabor platform?",
              content: [
                {
                  type: 'text',
                  text: "The O'Sabor platform is a digital menu for ordering fast-food. The platform consists of 2 applications for iOS, installed on the iPad integrated into the kiosks of the fast-food outlets within the OXXO stores. The two apps are O'Sabor Client (used by consumers to order food) and O'Sabor Kitchen (used by cooks to view orders and manage inventory)."
                }
              ]
            },
            {
              title: 'Which company requires it?',
              content: [
                {
                  type: 'text',
                  text: "FEMSA is a 130-year-old Mexican multinational conglomerate involved in the beverage industry and the commercial and restaurant sectors. It is headquartered in Monterrey, Nuevo Leon, Mexico, and operates in 10 Latin American countries and the Philippines. It is the largest Coca-Cola bottler in the world and the fifth-largest company in Mexico by sales."
                },
                {
                  type: 'text',
                  text: "The company is organized into four main areas: Coca-Cola FEMSA, FEMSA Commerce, Strategic Business, and Heineken. There are three divisions within FEMSA Commerce: Proximity, Health, and Energy. The company related to the O'Sabor platform (OXXO) is located within the Proximity division within the Commerce hub."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor2'
                },
                {
                  type: 'text',
                  text: "OXXO is the largest convenience store chain in Mexico, with a 73% market share, and the second-largest retailer. It has more than 19 thousand stores in Mexico, Chile, Colombia, and Peru; it serves more than 13 million customers per day and has more than 150 thousand employees."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor3'
                }
              ]
            },
            {
              title: "What's the opportunity?",
              content: [
                {
                  type: 'text',
                  text: "In Mexico, the fast-food industry was worth approximately 9 billion dollars in 2017, with an annual growth of 3% and an estimated value of more than 10 billion dollars by 2022."
                },
                {
                  type: 'text',
                  text: "There are more than 260,000 points of sale, including more than 19,000 OXXO stores in Mexico. FEMSA is the leader in fast food sales with an 8% market share."
                },
                {
                  type: 'text',
                  text: "Another essential factor to consider is that 40% of Mexicans go out to eat at least twice a week, mainly for lunch (58%) and mostly in fast food establishments (51%)."
                },
                {
                  type: 'text',
                  text: "The O'Sabor platform's opportunity lies in improving the fast-food shopping experience within OXXO stores to keep FEMSA as a market leader and reduce time, supplies, and personnel to increase profits."
                }
              ]
            },
            {
              title: 'Who are the users?',
              content: [
                {
                  type: 'text',
                  text: "The platform was initially launched in only three stores to test and obtain data. The three stores are located in 3 different areas of the Monterrey metropolitan area. From each of the regions, the following demographics stand out:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Zone 1. Mostly populated by retired or unemployed older women with low incomes. It also has many floating populations, mainly adult men with university studies and medium-high income.",
                    "Zone 2. Mostly populated by college-educated adults and seniors with medium to high incomes. The floating population is primarily medical personnel and patients from area hospitals.",
                    "Zone 3. Mostly populated by adults and elderly business people, with university studies and high income. The floating populations are construction workers and house cleaners."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor5'
                },
                {
                  type: 'text',
                  text: "In addition to the first test areas' profiles, it is essential to consider OXXO consumers' profiles and fast food consumers in Mexico."
                },
                {
                  type: 'text',
                  text: "The spectrum of consumers and users of OXXO's services is quite broad, does not distinguish or focus on any specific segment, and has strategies for all types of profiles and use cases, from those who make a quick stop on a road trip to homemakers."
                },
                {
                  type: 'text',
                  text: "About the profile of fast food consumers in Mexico, most of them are men, teenage students, and young adult workers without relevant socioeconomic distinction in teenagers and a slight predominance of adults' high socioeconomic sectors."
                }
              ]
            },
            {
              title: 'Who developed it?',
              content: [
                {
                  type: 'text',
                  text: "The project was developed by FEMSA Commerce's (OXXO) fast food and innovation teams. A manager and five developers integrated the innovation team; two focused on the back-end, two focused on mobile development, and me working as a product designer and front-end developer (mobile, web)."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor7'
                }
              ]
            },
            {
              title: 'What was my job?',
              content: [
                {
                  type: 'subtitle',
                  subtitle: 'Gathering information '
                },
                {
                  type: 'text',
                  text: 'To understand the context of the product, the market, the company, the users, the team, and all the work was done to date, including the problems already detected and the solutions previously proposed, I began a stage of information gathering through interviews, talks, field observation, data analysis, literature review, among other mechanisms.'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor9'
                },
                {
                  type: 'text',
                  text: 'I categorized the teams and key people to obtain information into four groups, reflecting their areas of knowledge, functions, needs, and interests. Within each of the groups, I identified three main profiles, listed below:'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor8'
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Users. The platform has three main user profiles: consumers (those who order food through the application), cooks (the store employees who receive the orders and cook the food), and cashiers (to whom the consumers pay for the food).",
                    "Tech team. The team in charge of the technological section of the platform has three main profiles: software developers (those who develop the applications), infrastructure engineers (those in charge of configuring networks, servers and all the technological infrastructure necessary for the operation of the apps) and field engineers (those in charge of attending to the technical problems that occur with the platform and the points of sale).",
                    "Design team. For the design, an external agency was hired, which had three main profiles: UI designers (who focused on the visual section of the application and some aspects of the brand), UX designers (who had conducted studies with users to detect needs and propose solutions, as well as test the prototypes), design managers (OXXO's primary contacts with the design agency, were mainly responsible for gathering requirements and agreeing on terms of work).",
                    "Business team. It is the team in charge of making profitable all the project and product proposals, and it mainly consists of business analysts (the primary connection between the technology team and the business team, they crossed the information provided by both sides), market analysts (in charge of making market and profitability studies of the project) and PMs (they were in charge of sponsoring the projects)."
                  ]
                },
                {
                  type: 'text',
                  text: 'It should be noted that each of the areas had very different visions from the others, which helped me to identify and obtain quite diverse problems and proposals for solutions.'
                },
                {
                  type: 'subtitle',
                  subtitle: 'First problems and first solutions'
                },
                {
                  type: 'text',
                  text: 'Before the existence of the platform, the process consisted of at least 11 analogous steps, some of them with a high margin of human error, within the problems identified in this first process stand out:'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor10'
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "The menu was set far away from the consumer, and some could not read it.",
                    "The menu was not updated continuously, so it did not include all the dishes.",
                    "The menu did not reflect available promotions.",
                    "Sometimes consumers had to ask many times about the availability of the dishes, which distracted the cooks.",
                    "If consumers misspelled their orders, they had to ask for another order form.",
                    "If consumers did not notice a misspelled order, the cooks would prepare the wrong dish, which increased the loss.",
                    "Sometimes there were no order forms available.",
                    "Sometimes consumers would take another consumer's order because they had ordered the same thing.",
                    "The cooks had to guide consumers through all the steps, which distracted them from their work.",
                    "The cashiers had to manually search the system for the dishes written on the form one by one to cash them out, which was time-consuming."
                  ]
                },
                {
                  type: 'text',
                  text: "To solve these problems, the innovation team, in conjunction with the fast-food team, proposed the first version of the O'Sabor platform, which initially consisted of 3 applications instead of 2 (Customer, Kitchen, Inventory). The platform reduced the flow from 11 to 7 steps, and by digitizing the menu allowed for more changes to reflect available products and promotions. It also solved problems with other physical elements, such as order forms."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor13'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor11'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor12'
                },
                {
                  type: 'subtitle',
                  subtitle: 'New problems and new solutions'
                },
                {
                  type: 'text',
                  text: 'Although most of the original problems detected were solved with the first version of the platform, I identified new issues through the information gathering rounds, which I organize into three main groups: communication interface, physical interface, and graphic interface.'
                },
                {
                  type: 'third-level-title',
                  third_title: 'Communication interface problems '
                },
                {
                  type: 'text',
                  text: 'Through the information gathered by talking to developers and users and long field observation sessions. I identified that a large number of usability issues were related to problems with the network infrastructure. Among the issues mentioned are the following:'
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Sometimes the consumer iPad couldn't connect to the kitchen iPad, and the cooks didn't get the orders.",
                    "Sometimes the consumer iPad could not connect to the point of sale, and the cashiers had to charge for products by searching for them manually one by one.",
                    "When the consumer iPad could not connect to the point of sale, there were problems in consolidating sales.",
                    "Sometimes the kitchen iPad could not connect to the consumer iPad to reflect available inventory."
                  ]
                },
                {
                  type: 'text',
                  text: 'The development team created a partial solution to mitigate these problems, printing two receipts, one to hand over to the cooks and others to hand over to the cashiers. The solution kept the system running but created other problems:'
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Consumers didn't read the receipts, so they didn't know what to do with each one.",
                    "Not knowing the connection problem and the two receipts' function, they did not give the corresponding one to the cooks, so their order was never prepared.",
                    "The issue of extra work for the cashiers and the traffic at the cash desk persisted."
                  ]
                },
                {
                  type: 'text',
                  text: 'The partial solution became so frustrating at times, that although the system continued working, the cooks decided to turn off the iPads and go back to using paper forms, so the underlying problem needed to be solved.'
                },
                {
                  type: 'text',
                  text: "The underlying problem was that the OXXO stores' internet network did not support the necessary simultaneous connections. The network was designed only to support the point of sale connection, and some vendor or occasional support employee. Due to the company's security policies, that configuration could not be changed in the short term or with the necessary scope for expanding the O'Sabor platform in other stores."
                },
                {
                  type: 'text',
                  text: 'Therefore, if C (consumer iPad) lost the internet connection, then it lost the connection to B (server and point of sale) and A (kitchen iPad), which caused all the problems mentioned above.'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor42'
                },
                {
                  type: 'text',
                  text: 'It should be noted that the most basic configuration of the platform consisted of only two iPad (one for consumer and one for the kitchen), and the most extensive setup consisted of eight iPad (four for consumers and four for kitchens). Network problems occurred even with the most basic configuration (2 stores); therefore, the most extensive setting (1 shop) interaction was impossible.'
                },
                {
                  type: 'third-level-title',
                  third_title: 'Communication interface solutions'
                },
                {
                  type: 'text',
                  text: 'While trying to solve the root problem with the infrastructure team (more capacity of simultaneous connections), I proposed a solution that, even though it was not the definitive one, solved all the original problems and the problems caused by the partial solution.'
                },
                {
                  type: 'text',
                  text: 'The solution was to create a P2P network between all the iPad using all the available connection channels (Wi-Fi networks, peer-to-peer Wi-Fi, and Bluetooth personal area networks). This way, if C (consumer iPad) lost the internet connection and therefore the connection with B (server and point of sale) and A (kitchen iPad), D (consumer iPad) or any other node could obtain the information from C through peer-to-peer Wi-Fi or Bluetooth and transmit it to the server.'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor43'
                },
                {
                  type: 'text',
                  text: 'With that solution, even when most of the iPad lost its Internet connection, the system could still work, as long as only one iPad had a connection and transmitted the information from the rest. And even with the total absence of the internet, the system could still work partially, by sending the orders directly from the consumer iPads to the kitchen iPads.'
                },
                {
                  type: 'text',
                  text: 'When this function was implemented, which I also developed, all the connection and related problems were solved, and the number of support requests was reduced practically to 0, thus improving the experience of cooks, consumers, and cashiers.'
                },
                {
                  type: 'third-level-title',
                  third_title: 'Physical interface problems'
                },
                {
                  type: 'text',
                  text: "Talking to the developers and field engineers, some platform security issues came to light, among them: "
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "The cooks were using the devices for personal use.",
                    "Some customers were closing the application and using other apps on the iPad."
                  ]
                },
                {
                  type: 'text',
                  text: "Also, by testing the store's device, I detected that it was possible to access the point of sale from the iPad browser by merely browsing the Safari history that the field engineers had forgotten to delete, which was a significant security breach."
                },
                {
                  type: 'text',
                  text: "The development team had already addressed all three issues, with a simple and effective solution, using the lock that includes iOS, which requires a three press of the Home button and the entry of the iPad unlock code to use another app. However, in some occasions, neither the development team nor the field engineers had been able to come to solve the platform problems and had asked the store employees to solve them with phone assistance, having to give them the unlock code, and although the code was changed later, in the course of that change the problems occurred."
                },
                {
                  type: 'text',
                  text: "Analyzing the kiosk's design, I understood that the problem was physical, since the metal box that contained and fixed the iPad had a hole (a.) through which you could press the Home button of the iPad, and that generated the three problems."
                },
                {
                  type: 'text',
                  text: "Other problems related to the kiosk that I could identify in the field tests were:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Some customers had to bend down a lot to interact with the screen.",
                    "Some customers had to bend down a lot to pick up their payment receipt (b.)."
                  ]
                },
                {
                  type: 'text',
                  text: "Both problems were caused by the lack of consideration of ergonomic aspects in the design of the kiosk."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor44'
                },
                {
                  type: 'text',
                  text: "Beyond the kiosk, other problems in the physical context were noteworthy:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "The platform did not reduce traffic at the checkouts.",
                    "The cooks didn't notice when the paper receipts were finished.",
                    "The cooks lost time shouting out orders, and the customers did not always hear them."
                  ]
                },
                {
                  type: 'third-level-title',
                  third_title: 'Physical interface solutions'
                },
                {
                  type: 'text',
                  text: "To solve the three security and the two ergonomics problems, I proposed two stages of updates to the kiosks, first, one that could be done quickly and with a low cost and then a more elaborate one that we could evaluate with the provider for a next version."
                },
                {
                  type: 'text',
                  text: "The first update consisted of a change in the height of the kiosk (c.) taking into account the average height of Mexicans (1.64m men and 1.58m women) and regional variations (as in the case of the city of Monterrey which is above the country's average) and a change in the position of the receipt printer, raising it to a position that did not require bending over in as many cases as possible."
                },
                {
                  type: 'text',
                  text: "But the most critical aspect of the first update was removing the hole that allowed the iPad's Home button to be pressed, which solved all the security problems."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor45'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor38'
                },
                {
                  type: 'text',
                  text: "Other solutions that I left for the future were the possibility of paying at the kiosk instead of having to go to the checkout and thus reduce the traffic in checkouts (already implemented) and the use of screens and speakers to communicate ready orders instead of the cooks having to shout it."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor37'
                },
                {
                  type: 'third-level-title',
                  third_title: 'Graphical interface problems'
                },
                {
                  type: 'text',
                  text: "The application introduced new issues related to its features and graphical interface in addition to the new infrastructure and physical elements problems. From the data collection sessions, I extract the following:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "The cooks were stressed out because they thought that the system measured the time it took for each order to be prepared.",
                    "Some cooks had difficulty reading the text on the screen.",
                    "Some customers had difficulty reading the text on the screen.",
                    "The cooks had to access a different application to deactivate the products, which caused some of them not to know how to return to the ordering app.",
                    "The design of the customer application was fixed, so the main sections could not be updated.",
                    "The prominence of the brand colors distracted users.",
                    "Loss of service attitude by interacting only with the system.",
                    "Difficulty in finding products.",
                    "Display of inaccurate products."
                  ]
                },
                {
                  type: 'text',
                  text: "Many reported and perceived problems were accessibility issues, although most of the consumer users were teenagers and young adults, but not the cooks, who were women over 40, some with vision problems. Of course, regardless of the dominant users, both applications had to be accessible."
                },
                {
                  type: 'text',
                  text: "Another part of the problems was due to the transition from the physical to the digital world, the learning curve, the loss of elements of the physical world, and the lack of an appropriate conceptual model."
                },
                {
                  type: 'text',
                  text: "To identify the critical elements of accessibility and interaction, I analyzed each of the interface elements listed below."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor17'
                },
                {
                  type: 'alphabet-list',
                  bullets: [
                    "The bar is unnecessary and is also a visual distraction, even as branding is superfluous as it ignores the physical space surrounding the kiosk, which is already filled with the logos and colors of the brand.",
                    "Non-accessible text",
                    "Differentiated product for no reason, simply to fill in the excess space as the layout was not dynamic.",
                    "Inconsistent and irrelevant icons, non-accessible text, same content as the grid in the Home."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor18'
                },
                {
                  type: 'alphabet-list',
                  bullets: [
                    "Text and button not accessible.",
                    "Text not accessible. There is no consistency in accessing the shopping cart, some screens include this block, and others do not."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor19'
                },
                {
                  type: 'alphabet-list',
                  bullets: [
                    "Non-accessible text",
                    "Non-accessible text",
                    "Use of the primary color of clickable elements in the divider.",
                    "Buttons are indistinguishable from the regular text, do not appear clickable, and do not differentiate between primary and secondary action."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor20'
                },
                {
                  type: 'alphabet-list',
                  bullets: [
                    "The cart has no title, and instead, there is an option to empty it that is not accessible, and its text is not different from the regular text.",
                    "Non-accessible.",
                    "No differentiation of clickable element."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor21'
                },
                {
                  type: 'alphabet-list',
                  bullets: [
                    "Non-accessible.",
                    "Non-accessible.",
                    "Text and buttons do not make sense; the title is not related to the description and the action; the total is located after the final action, there is no way to cancel or go back."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor22'
                },
                {
                  type: 'alphabet-list',
                  bullets: [
                    "Useless and distracting bar.",
                    "Confusion if it was to collapse or to cancel the order.",
                    "Clickable color on the divider.",
                    "Colors inconsistent with the rest of the app.",
                    "Indicators not accessible and without filtering capacity"
                  ]
                },
                {
                  type: 'third-level-title',
                  third_title: 'Graphical interface solutions'
                },
                {
                  type: 'text',
                  text: "Not only did I gather information about the GUI problems, but throughout the process, all the teams gave me valuable ideas and suggestions. Among the ideas I received was to reinforce the shopping cart metaphor, which at first sight seemed natural, given everyone's familiarity with online shopping."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor23'
                },
                {
                  type: 'text',
                  text: "However, the metaphor didn't have a clear connection with the real world; fast food, especially Mexican fast food, is not usually purchased in shopping carts, which could create a confusing conceptual model for those who are not familiar with online shopping (the penetration rate of online shopping in Mexico is 55%), on the other hand in the physical world and other OXXO stores people use menus and order forms, so I decided to use that metaphor, plus it resulted in an interface similar to the previous one."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor24'
                },
                {
                  type: 'text',
                  text: "Taking into account the suggestions and problems, I proposed the following redesign."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor25'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor26'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor27'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor28'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor29'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor30'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor31'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor32'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor33'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor34'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor35'
                },
                {
                  type: 'diagram',
                  diagram: 'osabor36'
                },
                {
                  type: 'text',
                  text: "Besides the improvements in the flows, interactions, and visual aspects, another essential proposal and update was to make the application context-aware."
                },
                {
                  type: 'text',
                  text: "This is because, with the first version, people arrived at the store at 1 pm, approached the kiosk to order lunch, and the first thing they saw was an interface that prominently offered them to order chilaquiles, or when entering the drinks section, it displayed them hot coffee. Both options don't seem to be a big problem if it weren't because chilaquiles are culturally a breakfast and are not usually eaten at lunch, and hot coffee is an unbearable drink at that time in Monterrey (it averages a maximum temperature of 32 degrees Celsius)."
                },
                {
                  type: 'text',
                  text: "If the interaction were with a human, the consumer would never receive those suggestions at that time, so the problems of finding suitable products and the loss of service attitude were given because we substituted the interaction with the cooks with an interaction with a silly application, which ignored its context and provided unsatisfactory results."
                },
                {
                  type: 'text',
                  text: "To solve these problems, I proposed a project called proactive interfaces, which used all available information and machine learning sources to provide the right products for the consumer's context. The sources of information were the following:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "APIs. From our services and third-party services, we obtained the weather, recurrent or extraordinary events in the area, promotions and product data, and predefined rules for the environment.",
                    "iPad. We obtained the day and time and location from the iPad, which was crossed with our own and third-party APIs.",
                    "Consumers. From the consumers through the iPad, we got the clickstream. ",
                    "Beacons. From the beacons I installed in the store, as a test for another project, we obtained the temperature of the store and the traffic."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'osabor40'
                },
                {
                  type: 'text',
                  text: "The results of the processing of these entries were reflected in a new main screen: Suggestions; which showed products categorized contextually, for example, foods suitable for the time of day, for the season (since for example, tamales are usually more popular at the end and beginning of the year), for the temperature or most popular foods in a particular store based on previous choices of consumers. Also, all items on all lists were ordered based on the same factors."
                },
                {
                  type: 'diagram',
                  diagram: 'osabor41'
                },
              ]
            },
            {
              title: 'What were the results?',
              content: [
                {
                  type: 'text',
                  text: "In addition to the solution of the problems mentioned above, once the improvements were implemented, the project began its expansion in the Monterrey metropolitan area and two other cities in Mexico."
                },
                {
                  type: 'text',
                  text: "In terms of economics, the profit for extra ingredients was increased by 3 MXN per order, and the loss was considerably reduced by reducing the number of wrong dishes due to human error."
                },
                {
                  type: 'text',
                  text: "And in terms of consumer experience, the order time was reduced from 5 minutes to 1 minute. And the number of calls from the team of cooks to report problems was reduced to practically zero, as were the complaints collected in the field."
                },
                {
                  type: 'text',
                  text: "Also, as can be seen in this narration, most of the usability problems detected were solved, and flows were optimized, and the quality of service improved"
                }
              ]
            }
          ]
        },
        {
          id: 'wifimall',
          title: 'Wifimall web and mobile app design',
          thumbnail: 'wifimall-thumbnail',
          hero: 'wifimall-hero',
          date: '2016 - 2017',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            },
            {
              name: 'web',
              url: ''
            }
          ],
          company: {
            name: 'BBXM Studios',
            url: 'http://bbxmstudios.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "What was Wifimall?",
              content: [
                {
                  type: 'text',
                  text: "A digital platform that provided internet in shopping malls in exchange for seeing advertising for their shops and restaurants. It consisted of an application for consumers (where they saw advertising and obtained coupons and internet) and an application for administrators (where they managed their advertising and coupons, saw their performance, and redeemed them)."
                }
              ]
            },
            {
              title: "Who developed it?",
              content: [
                {
                  type: 'text',
                  text: "The project was developed by a small startup called BBXM, which had two products: a video game subscription platform and Wifimall. The startup was integrated by its CEO, two managers, five developers, two designers, and me, working as a front-end developer (mobile, web) and product designer."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall1'
                }
              ]
            },
            {
              title: "Who were the clients?",
              content: [
                {
                  type: 'text',
                  text: "The platform's main client was the Acosta Verde Group, the largest developer and operator of malls in Mexico. It has developed 40 shopping malls in 16 Mexican states and currently operates 20 of them. Other points to emphasize are:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "It develops between 4 and 5 new malls per year.",
                    "From 2016 to 2017, it increased its value from 243 million dollars to 427 million dollars."
                  ]
                }
              ]
            },
            {
              title: "Who were the users?",
              content: [
                {
                  type: 'text',
                  text: "In terms of users, the platform had three main groups:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Acosta Verde staff: The people that managed the malls, either from the mall or the AV headquarters.",
                    "Tenants and their staff: The owners of stores and restaurants and the team that works for them (managers, cashiers, cooks, etc.).",
                    "Consumers: The people who visit the mall and consume the tenants' products and services."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall2'
                },
                {
                  type: 'text',
                  text: "It is worth noting that the 20 malls have 4800 of the tenants, growing at 1000 per year. Each mall has one or several anchor tenants such as Soriana Híper, Casa Ley, S-Mart, Merco and Cinépolis and one or several sub-anchors such as City Club, Suburbia, Coppel, Famsa, Del Sol, and Woolworth; as well as fast-food restaurants such as Carl's Jr., Domino's, Kentucky Fried Chicken and Pizza Hut and small clothing, furniture and electronics shops."
                },
                {
                  type: 'text',
                  text: "Regarding the consumers, each of the places receives 500,000 to more than 1,200,000 visits per month. The spectrum of profiles is quite broad, but about Mexican consumers, the following data stands out:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Approximately 30% of Mexico's population is part of the 'Great Consumer,' a segment that generates the bulk of consumption and comprises the A/B, C+, and C levels.",
                    "Mexico City has the highest monthly family income at the national level, with 31,487 MXN, followed by Sinaloa with 27,091 MXN.",
                    "According to ENIGH 2016, Mexico City, Nuevo Leon, and Queretaro are the states with the highest presence of high socioeconomic levels A/B, since between 10% and 11% of their population corresponds to this segment.",
                    "On the other hand, most low socioeconomic levels are located in the southern region of the country, in Guerrero, Chiapas, and Oaxaca.",
                    "At the national level, 56% of the population corresponds to low socioeconomic levels, with the remaining 44% distributed between medium and high levels."
                  ]
                }
              ]
            },
            {
              title: "What was my job?",
              content: [
                {
                  type: 'text',
                  text: "I worked on the three main pieces of the product (Connection flow, Consumer app, Tenant app) as a product designer and developer, I also took care of the scrum ceremonies and artifacts, and in general, I was a facilitator to help the team deliver on time."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall5'
                },
                {
                  type: 'subtitle',
                  subtitle: "Connection flow"
                },
                {
                  type: "text",
                  text: "The connection flow encompassed the process of giving the consumer internet and the components to achieve this, including the API, Meraki (internet provider) hardware, digital and physical advertising, and connections to iOS and Android apps and the captive portal."
                },
                {
                  type: "text",
                  text: "In this piece of the product, I worked mainly on two things:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "Collecting qualitative and quantitative information",
                    "API connection with captive portal and iOS app"
                  ]
                },
                {
                  type: "text",
                  text: "In the first point, I was mainly in charge of observing, interviewing, and testing with users. Also, I did literature review and monitors usage metrics, which we obtained from Meraki devices and the analytical tools I integrated (Mixpanel and Google Analytics)."
                },
                {
                  type: "text",
                  text: "In the first iterations, I focused mainly on validating consumers' interest in the free internet, knowing their profile and the way they used the internet: which sites they visited, what kind of content they consumed, at what times they connected, and where in the mall they connected."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall6'
                },
                {
                  type: "text",
                  text: "I took advantage of the technical testing stage to validate the first point. While validating that Meraki's technology worked and had the necessary coverage and capability, I collected usage information directly from consumers and Meraki devices. The initial flow consisted of a simple connection process, without any requirements or intermediate steps."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall9'
                },
                {
                  type: "text",
                  text: "From the information collected during the first flow tests, the following stand out:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "Consumers made high use of the internet, mainly to watch videos and even movies, and considered it an essential amenity in shopping malls.",
                    "The area of the plaza where the internet was most used was in the food court.",
                    "Some restaurants provided internet to their consumers, but consumers preferred to use our network because they did not want to ask for the wifi password.",
                    "Most of the restaurants did not include the wifi password on the ticket.",
                    "For store employees, it was a distraction to respond to requests for the wifi password.",
                    "Given the high number of people at the counter of each restaurant, it was difficult for the consumer to return to ask for the wifi password.",
                    "Although smartphone penetration was already high in Mexico in 2017, mobile internet penetration was not.",
                    "Mobile internet plans provided very few GB for a very high price, making them useless for consuming audiovisual content."
                  ]
                },
                {
                  type: "text",
                  text: "In short, the process of obtaining the internet was quite frustrating."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall7'
                },
                {
                  type: "text",
                  text: "Consumers were interested in the internet everywhere in the mall as an amenity, especially in the food court."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall8'
                },
                {
                  type: "text",
                  text: "Also, from the secondary research, I extracted contextual data from the three groups of users (which I partially mentioned in the previous section) and data on the trends of the retail industry in Mexico, of which the following stand out"
                },
                {
                  type: "decimal-list",
                  bullets: [
                    "The transformation of shopping centers into entertainment and service locations (something that e-commerce cannot offer) will continue.",
                    "The incorporation of technology through websites, social networks, digital communication with consumers, and online offerings will continue.",
                    "There is a trend toward smaller shopping centers.",
                    "Similarly, department, self-service, and specialty store formats have become smaller.",
                    "Shopping malls will begin to include distribution points for online purchases.",
                    "The construction of shopping centers in the country's main metropolitan areas continues in the entertainment, lifestyle, and mixed-use formats."
                  ]
                },
                {
                  type: "text",
                  text: "It should be noted that points 2 and 5 are related to the digital transformation in the stores that the Wifimall platform intended. The platform was mostly aligned with point 2."
                },
                {
                  type: "text",
                  text: "Once the technological preparation and consumer interest in the internet in the mall were validated and having some contextual information, the team added the captive portal with advertising to the connection flow."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall10'
                },
                {
                  type: "text",
                  text: "The captive portal added a new layer of complexity, so in this iteration, I paid particular attention to the funnel, in addition to beginning to evaluate key product metrics such as time spent in the plaza, relative consumer satisfaction, and average consumer spending."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall11'
                },
                {
                  type: "text",
                  text: "Testing the second flow, I found that the captive portal with advertising had no significant impact on the number of connections, nor on the purchase of the advertised products, but there was a negative impact on the perception of trust in the product as some consumers believed their device had been 'infected.'"
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall12'
                },
                {
                  type: "text",
                  text: "In the following iterations, we added some changes that negatively impacted the use and perception of trust; the main one was to add a timer that forced consumers to watch the advertising for 5 seconds. I also did A/B tests with different graphic options with graphic designers' advertising and instructions."
                },
                {
                  type: "text",
                  text: "The instructions to connect eventually became instructions to download the app in the last iterations, since once the development of the consumer app was completed, we started migrating the connection services to the app, making it necessary to connect, so the most extended final flow came to consist of 7 steps (New consumer with Android device). With the new flow, we were looking for three things:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "Downloading the application",
                    "Advertising view",
                    "Internet connection"
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall13'
                },
                {
                  type: "text",
                  text: "It should be noted that the increased complexity of the connection flow considerably reduced connections and again negatively impacted product confidence, which we had to level off in conjunction with Acosta Verde with an extensive physical and digital advertising campaign. "
                },
                {
                  type: "text",
                  text: "The mall was filled with advertising on the internet, and instructions for downloading the app were installed on the food court tables. Plus, the tenants began to mention the product to their consumers. Trust by reference was significant and helped reduce the negative impact on consumer perception."
                },
                {
                  type: 'subtitle',
                  subtitle: "Consumer app"
                },
                {
                  type: 'text',
                  text: "The consumer app contained the tenants' coupons and the codes to validate them and gave the internet to the users in the last stage."
                },
                {
                  type: 'text',
                  text: "In this part of the product, I worked mainly on two things:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Product design",
                    "iOS app development"
                  ]
                },
                {
                  type: 'text',
                  text: "Initially, the app was developed only for Android since it was the primary market and could not provide the internet connection; it only had coupons' functionality. The flow of the consumer app was as follows:"
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall14'
                },
                {
                  type: 'text',
                  text: "Although the interaction tests of the first versions of the app were quite good (Both in prototypes and the product already developed), in real life, consumers did not download it or download it and did not open it or opened it only for a few seconds. "
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall15'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall16'
                },
                {
                  type: 'text',
                  text: "This was because the app had nothing to do with getting on the internet, and consumers were not interested in the promotions."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall17'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall18'
                },
                {
                  type: 'text',
                  text: "Once we migrated the connection functionality from the captive portal to the app, the latter became necessary to provide the internet, and the captive portal started to indicate it. However, this caused a profound rejection in the consumers; the connection flow that integrated the app was a failure; we had users leaking in all the steps, and in tests and interviews, they reported a terrible perception of the new flow. The rejection would not go down until Acosta Verde, and the tenants helped us with physical advertising and referrals, respectively."
                },
                {
                  type: 'text',
                  text: "With the second flow of the app, the consumer was notified upon arrival at the mall that the network was available and that they should open the app, and when they opened it, a pop-up showed them advertising and connected them to the internet. While the flow was relatively simple for those who already had an account and the app installed, it was the opposite for those who had neither."
                },
                {
                  type: 'text',
                  text: "Once the app fulfilled the two essential functions of the product, I was in charge of redesigning the Coupons screen experience, which to date, had been a simple listing of all coupons with clear limitations of order, flexibility, and relevance. I took into account mainly four entities: the environment, the consumer, the staff, and the coupons; and from each entity, I took into account some factors like date and time, viewed coupons, redeemed coupons, favorite coupons, featured coupons (Editorial), category of the day (Editorial), coupon category, coupon views, coupon redemptions, and valid dates. "
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall19'
                },
                {
                  type: 'text',
                  text: "The various entities' factors could be crossed automatically or editorially to fill sections in the Coupons screen; the sections could be unlimited and with three different layouts. For this, I created three variants of the coupon component that could fit into the section component."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall20'
                },
                {
                  type: 'text',
                  text: "In this way, I created a dynamic, flexible, and above all, more intelligent and personalized Coupons screen for the consumer's context."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall21'
                },
                {
                  type: 'text',
                  text: "Among the many improvements made in the following iterations, one of the most relevant was to attack users who were not in the mall to connect even more with the physical and digital world and compete with the giants of e-commerce. To do this, I replicated the model I had done with the Coupons screen, creating a system of contextual offers based on the same entities and new factors related to each one. By crossing the information, we could trigger a notification that would alert the user of an existing offer relevant to their context and thus attract them to the mall and improve their experience inside and outside the mall, in addition to expanding the scope tenants' coupons and giving them more intelligent advertising tools."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall22'
                },
                {
                  type: 'subtitle',
                  subtitle: "Tenant app"
                },
                {
                  type: 'text',
                  text: "The tenant app was used to create the coupons reflected in the consumer app, manage them, redeem them, view their statistics, and add team members who could use the app."
                },
                {
                  type: 'text',
                  text: "In this piece of the product, I worked mainly on two things:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Product design",
                    "Customer Development"
                  ]
                },
                {
                  type: 'text',
                  text: "The first version consisted of a simple section to redeem the Wifimall team's coupons preloaded in the app. "
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall23'
                },
                {
                  type: 'text',
                  text: "My goal in the first version was to do both technical and user tests, validate the proper functioning of all components together, and collect usage information through tests, interviews, observation, and analytical tools, and iteratively understand the tenants' needs."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall24'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall25'
                },
                {
                  type: 'text',
                  text: "The first version had no significant problems on the tests; in fact, we received good comments on the app's speed because the app worked in real-time, so as soon as the code was finished being entered, it was immediately validated."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall27'
                },
                {
                  type: 'text',
                  text: "In the second iteration, I added the functionality to create, edit, and delete coupons. "
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall28'
                },
                {
                  type: 'text',
                  text: "In the coupon creator, I paid particular attention to reflect exactly how the coupons would look in the app since the images' formats were not standardized, and most of the tenants downloaded them from the internet. With a previewer, they could see the impact of uploading an inappropriately formatted image and improve the consumer experience."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall29'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall30'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall31'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall32'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall33'
                },
                {
                  type: 'text',
                  text: "It should be noted that our first users in the tenant group were the owners or general managers of the stores. Their average age was around 50, so it was vital to include instructions in each section and clarify which elements were clickable and which were not. "
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall35'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall36'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall37'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall38'
                },
                {
                  type: 'text',
                  text: "Again the real-time functions and previews were very well received in the tests. However, the previewer did not wholly solve tenants' problem uploading images with inadequate or poor quality formats. When I researched this problem, I found that the tenants had difficulty uploading coupons for a simple reason: they did not have designs. The small businesses did not have any designs, and the owners told me that they could not invest in someone to make them. On the other hand, the franchisees had coupons in different formats and did not have a local design staff to help them adjust the coupons to the format of the application. Another problem I detected in testing was that the unique code per coupon we created was very long, which resulted in errors and lost time even in controlled testing."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall39'
                },
                {
                  type: 'text',
                  text: "So in the next iteration, I focus on attacking the problem of the absence of designs. To do this, I designed and integrated a coupon designer with which the renters and their employees could choose from several templates and color combinations and add text to them to see in real-time how their coupon would look in the app."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall40'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall41'
                },
                {
                  type: 'text',
                  text: "Again I paid particular attention to integrate the exact look of how the coupon would look, including part of the app interface and not just the image. "
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall42'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall43'
                },
                {
                  type: 'text',
                  text: "Once the product (all three pieces) left its essential testing phase and started its expansion, the tenants began having problems sharing their accounts with their employees or subordinates, so I integrated a Team section."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall44'
                },
                {
                  type: 'text',
                  text: "Although the Team section did not need a preview function like couponing, I decided to standardize and compose all the screens."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall45'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall46'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall47'
                },
                {
                  type: 'text',
                  text: "In this way, I created a unified and scalable experience, which sat in real-time and previews."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall48'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall49'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall50'
                },
                {
                  type: 'text',
                  text: "Finally, another problem detected in the previous iteration and solved in this one was creating shorter codes."
                },
                {
                  type: 'text',
                  text: "Once the tenants could perform all the essential administrative functions, my next step was to use the information we already had about coupon performance to create an Insights section, which would help the tenants make strategic decisions about their promotions and their business in general and also complement the new dynamic Coupons screen in the consumer app."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall51'
                },
                {
                  type: 'text',
                  text: "To do this, I replicated the model of the Coupons screen in the consumer app. In this way, by crossing the different entities' variables, I could advise the tenants to improve their business performance and their coupons, which directly impacted the consumer experience by receiving more intelligent and contextual advertising."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall52'
                },
                {
                  type: 'text',
                  text: "I found that some tenants, especially their employees, were unaware of many environmental variables (Such as day categories), which negatively impacted their advertising effectiveness."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall53'
                },
                {
                  type: 'text',
                  text: "The interface followed the same model of the other screens already known by the users. It was adopted with a minimum learning curve."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall54'
                },
                {
                  type: 'text',
                  text: "Finally, as tenants' registration increased, the experience of the on-boarding process of tenants became a burden for all parties involved (Acosta Verde, tenants, Wifimall team)."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall55'
                },
                {
                  type: 'text',
                  text: "The first flow was analogous and consisted of a triangulation of information and confirmations between the three parties, which more than once led to errors."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall56'
                },
                {
                  type: 'text',
                  text: "Although for the tenants, the successful process consisted of 3 steps. If errors occurred, they could enter into an infinite loop until they were resolved. Also, the Wifimall team did not have the human resources to deal with registration requests or information changes and develop the product simultaneously."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall57'
                },
                {
                  type: 'text',
                  text: "Therefore, I created an on-boarding process integrated into the tenants' app that was entered directly through a unique URL or a mail link."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall58'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall59'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall60'
                },
                {
                  type: 'text',
                  text: "The flow was still three steps, but without constant intervention by the other two parties and less time to solve errors. This significantly reduced the workload for all parties and reduced communication problems."
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall61'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall62'
                },
                {
                  type: 'diagram',
                  diagram: 'wifimall64'
                }
              ]
            },
            {
              title: "End notes",
              content: [
                {
                  type: 'text',
                  text: "The platform with the functions described here performed quite well and slowly contributed positively to all the key factors: consumer satisfaction with the mall's amenities, time spent in the mall by consumers, average spending per ticket, and interest of the tenants in establishing in the Acosta Verde Group's malls; it was also the first of its kind in shopping malls in Mexico. However, there was enormous pressure during the process to include more advertising, more notifications, other types of income such as pay per download, which was diminishing the experience until finally, the platform disappeared one year after I stopped working on it."
                }
              ]
            }
          ]
        },
        {
          id: 'kinedu',
          title: 'Kinedu web app redesign',
          thumbnail: 'kinedu-thumbnail',
          hero: 'kinedu-hero',
          date: '2015 - 2016',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: 'https://apps.apple.com/bo/app/kinedu-estimulacion-temprana/id741277284'
            },
            {
              name: 'Android',
              url: 'https://play.google.com/store/apps/details?id=com.kinedu.appkinedu'
            },
            {
              name: 'web',
              url: 'https://www.kinedu.com'
            }
          ],
          company: {
            name: 'Kinedu',
            url: 'https://www.kinedu.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: 'What is the Kinedu app?',
              content: [
                {
                  type: 'text',
                  text: "It is an app used by more than 3 million families in over 180 countries that offers expertly backed orientation videos and activities, allowing parents to stimulate their children's development and track their progress daily. Designed to assist in the parenting of children ages 0-4, Kinedu provides an experience based on both children and their parents' needs."
                }
              ]
            },
            {
              title: 'Who develops it?',
              content: [
                {
                  type: 'text',
                  text: "The application is developed by a startup of the same name, founded in 2013 by a team that had already worked in another daycare startup and had already acquired knowledge about pedagogy, psychology, and childcare. In 2015 the startup established a close collaboration with Dr. Michael Frank from the Department of Psychology at Stanford University, which laid the app's foundation."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu1'
                },
                {
                  type: 'text',
                  text: "At the time I joined the team, it consisted of the CEO/founder, a CMO, a CTO, a PO, three content generators, a mobile developer, a back-end developer, a designer, and myself working as a web developer, product designer and occasionally helping with mobile development. When I left the team two years later, a designer, a mobile developer, a back-end developer, a content person, and a marketing person joined."
                }
              ]
            },
            {
              title: 'Who are the users?',
              content: [
                {
                  type: 'text',
                  text: "The application is mainly aimed at parents with one or more children from 0 to 4 years and secondarily at other family members or private caregivers. The content is available in English, Spanish, and Portuguese, so the three countries where the largest number of users are located are the USA, Mexico, and Brazil, but it should be noted that the app is available worldwide, so Europe is another region with a significant number of users in addition to North America and Latin America."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu2'
                },
                {
                  type: 'text',
                  text: "Worldwide, users' age is between 18 and 30 years old, with a socioeconomic profile mostly between C and A/B and are primarily women. It should be noted that the profile varies by country, mainly because the average age of new mothers differs significantly from country to country, with the youngest being in Angola (18), Bangladesh, Chad (18.1), and Mali (18.6); and the oldest in Greece (31.2), Australia (30.5), South Korea, Japan, and Italy (30.3). Of the three countries with the most users, the average age is Mexico (21), the USA (26), and Brazil (30)."
                },
                {
                  type: 'text',
                  text: "Another data to emphasize, since it indicates the users' possible active life, is the average number of children per family that worldwide is located in 2.5, in North America 1.8 and Latin America and the Caribbean 2.2."
                }
              ]
            },
            {
              title: 'What was my job?',
              content: [
                {
                  type: 'subtitle',
                  subtitle: "Introduction"
                },
                {
                  type: 'text',
                  text: "I joined Kinedu's team to support the iOS app development, especially the iPad version that was about to be released. However, when I started collecting information about the users and the work done so far, I realized that the weakest flank was the web app."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu4'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu3'
                },
                {
                  type: 'text',
                  text: "While the focus of the company was the iOS app, the web app had a considerable amount of users because:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "People with Android devices used the web app since Kinedu did not have an Android app.",
                    "It was an important registration channel for new users because the primary acquisition channel was Facebook, and many users used FB on their desktop computers.",
                    "When users had a problem with payments and subscriptions on the mobile app, they used to go to the web app."
                  ]
                },
                {
                  type: 'text',
                  text: "But those users did not experience a product at the level of the iOS app, since the web app was full of problems among which they stood out:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Performance issues and bugs",
                    "Different design from the mobile app.",
                    "Different functions than the mobile app, some of them already discontinued."
                  ]
                },
                {
                  type: 'text',
                  text: "The existing use of the web app and its problems validated the need for a new version that would solve all the issues and offer an experience at the level of the iOS app, so I started working on its design and development."
                },
                {
                  type: 'subtitle',
                  subtitle: "Leveling the mobile app experience"
                },
                {
                  type: 'text',
                  text: "It was clear that the web app had to offer an experience on a par with the iPhone app (which has been recognized on several occasions as one of the best in the App Store and as the app of the day) and the new iPad app. But that didn't mean simply copying the experience of iOS apps, but meeting the specific needs of web app users and creating a familiar experience for their existing platforms and services."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu7'
                },
                {
                  type: 'text',
                  text: "I validated that most web app users used Android devices and not iOS devices through interviews and reviewing database records and analytics tools. Similarly, some argued that if a Kinedu app existed for Android, they would use it instead of the web app even if it was responsive. Therefore I defined the following guidelines for the first iteration of the new version:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "The web app should be familiar to Android users and desktop web users.",
                    "The web app should not copy the iOS app design since its interaction patterns and appearance were alien to Android users.",
                    "The web app had to be responsive and packaged as an app to be uploaded to the Play Store to fill that gap."
                  ]
                },
                {
                  type: 'text',
                  text: "As a first step, I focused on identifying the main entities that interacted with the product and how they interacted with each other, and the features we offered. Kinedu broadly interacted with two entities, adults and babies, and these were grouped into families. It is worth noting that what made the product somewhat complicated was the grouping by families and their relationship with the adults and babies since a family could have many adults and babies and vice versa. Likewise, another factor of confusion was: to whom the product subscription belonged? The family or the adult as an individual? At that time, it belonged to the adult as an individual and was transmitted to other adults through the family he/she created. The rest of the information belonged to the baby as an individual since it was exclusive of his/her properties and progress."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu10'
                },
                {
                  type: 'text',
                  text: "The iOS app navigation and structure reflected these entities and relationships."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu8'
                },
                {
                  type: 'text',
                  text: "In this structure, the baby was the center, and the rest of the entities were accessed through him/her."
                },
                {
                  type: 'text',
                  text: "In the web app, I decided to follow a hierarchical flow that could help understand the entities' relationships. Although the baby was still the center of the structure, it was accessed through a hierarchical flow, accessing first the family, then the user, and finally the baby as an individual and the sections related to him/her."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu9'
                },
                {
                  type: 'text',
                  text: "The web app's navigation reflected this hierarchical organization. The main navigation was based on the following flow:  family's selection, user's visualization, baby's selection, and section's selection. All of which were organized in an index/detail pattern."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu11'
                },{
                  type: 'diagram',
                  diagram: 'kinedu12'
                },{
                  type: 'diagram',
                  diagram: 'kinedu13'
                },{
                  type: 'diagram',
                  diagram: 'kinedu14'
                },{
                  type: 'diagram',
                  diagram: 'kinedu15'
                },{
                  type: 'diagram',
                  diagram: 'kinedu16'
                },{
                  type: 'diagram',
                  diagram: 'kinedu17'
                },{
                  type: 'diagram',
                  diagram: 'kinedu18'
                },{
                  type: 'diagram',
                  diagram: 'kinedu19'
                },{
                  type: 'diagram',
                  diagram: 'kinedu20'
                },{
                  type: 'diagram',
                  diagram: 'kinedu21'
                },{
                  type: 'diagram',
                  diagram: 'kinedu22'
                },{
                  type: 'diagram',
                  diagram: 'kinedu23'
                },{
                  type: 'diagram',
                  diagram: 'kinedu24'
                },{
                  type: 'diagram',
                  diagram: 'kinedu25'
                },{
                  type: 'diagram',
                  diagram: 'kinedu26'
                },{
                  type: 'diagram',
                  diagram: 'kinedu27'
                },{
                  type: 'diagram',
                  diagram: 'kinedu28'
                },{
                  type: 'diagram',
                  diagram: 'kinedu29'
                },{
                  type: 'diagram',
                  diagram: 'kinedu30'
                },{
                  type: 'diagram',
                  diagram: 'kinedu31'
                },{
                  type: 'diagram',
                  diagram: 'kinedu32'
                },{
                  type: 'diagram',
                  diagram: 'kinedu33'
                },{
                  type: 'diagram',
                  diagram: 'kinedu34'
                },{
                  type: 'diagram',
                  diagram: 'kinedu35'
                },{
                  type: 'diagram',
                  diagram: 'kinedu36'
                },{
                  type: 'diagram',
                  diagram: 'kinedu37'
                },
                {
                  type: 'text',
                  text: "With the new version of the web app, I included all the iOS app features, and I took elements from the new iPad app (Home section) to serve as a bridge between the two experiences. Also, by rewriting the web client from scratch, using better technologies and better architecture, I could eliminate most performance issues and bugs."
                },
                {
                  type: 'subtitle',
                  subtitle: "First tests"
                },
                {
                  type: 'text',
                  text: "During the design and development of the web app, it was vital to obtain both qualitative and quantitative information, so I took advantage of the reconstruction of the web client to integrate several tracking and testing tools (which we would test), among them:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Optimizely",
                    "Firebase",
                    "Google Analytics",
                    "Mixpanel"
                  ]
                },
                {
                  type: 'text',
                  text: "With Optimizely, I performed A/B tests on the web app's key screens, such as the plan and payment section and the configuration screens where the user could unsubscribe. With Firebase, among other things, I monitored the performance of the app, primarily through Crashlytics. With Google Analytics and Mixpanel, I integrated tags in critical flows, and I could know more about the users' profiles and where they were having problems in the app."
                },
                {
                  type: 'text',
                  text: "Also, we conducted a series of face-to-face and remote testing sessions with moms and dads, other family members, and caregivers while we obtained information through the tools. Some of the mechanisms we followed in the face-to-face sessions were:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Surveys and questionnaires.",
                    "Interviews.",
                    "Tests with users and observation.",
                    "User diaries.",
                    "Focus group."
                  ]
                },
                {
                  type: 'text',
                  text: "Finally, within the app, together with the CMO and the other product designer, we designed a screen to obtain the NPS with which we could get quantitative (score) and qualitative (feedback) information, and we added to the set of data the opinions we obtained from the App Store, social networks, emails, and the server logs."
                },
                {
                  type: 'text',
                  text: "Among all the information gathered in the first stage of testing, the following negative points stand out:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "The Home was a total failure. The users did not visit the other sections of the app, nor did they reach the app's central point (the Daily Activity Plan). We identified that most users did not know what to do with the Home screen; it was difficult for them to scan and understand.",
                    "Users did not understand the 'Family' feature.",
                    "Many users did not indicate that they had already completed their babies' activities, so the algorithm could not suggest the appropriate content.",
                    "We lost many new users in the onboarding process, especially during the initial assessment step.",
                    "Some texts within the app were inadequate for their context (e.g., Delete Baby)."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu38'
                },
                {
                  type: 'text',
                  text: "Taking into account the information we obtained from the tests, I make the following changes:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Remove the home page and give more prominence to the Daily Activity Plan.",
                    "Create a new 'Articles' section following the index/detail pattern.",
                    "Revise and reformulate the app's texts, especially those related to editing the information of each baby."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu39'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu40'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu41'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu42'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu43'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu44'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu45'
                },
                {
                  type: 'text',
                  text: "I left some Family and onboarding proposals in the inkwell, awaiting the next round of testing."
                },
                {
                  type: 'subtitle',
                  subtitle: "More tests"
                },
                {
                  type: 'text',
                  text: "In addition to the evaluation mechanisms mentioned above, in the second round of testing, I sought to collect information from non-users by explaining the product and seeing them interact with it for the first time."
                },
                {
                  type: 'text',
                  text: "From this second round of testing, I understood that some of the app's aspects were difficult to explain. That was in line with the feedback we were getting on those aspects. Some of the highlights were:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Users did not understand the concept of the Daily Activity Plan.",
                    "The activities were too many for most parents and babies.",
                    "Parents had the feeling of losing their activities every week and never seeing them again.",
                    "Parents wanted to start using the app before their babies were born.",
                    "Parents would stop using the app when their babies turned two years old.",
                    "We received several inclusion complaints since most of the illustrations on the landing page, social networks and app, were from straight families with white skin and light hair (Kinedu's default illustration was a blonde baby).",
                    "Users wanted direct contact with experts.",
                    "Users wanted to add more follow-up information on their babies, such as weight and height."
                  ]
                },
                {
                  type: 'text',
                  text: "Since most of these points were not exclusive to the web app but referred to the app's operation on any platform, the third stage of the web app updates focused on designing and developing proposals that could later be implemented in the iOS app."
                },
                {
                  type: 'text',
                  text: "The first action was to reformulate the Daily Activity Plan as a Weekly Activity Plan. Instead of five days, it would be integrated by seven days, following the week's natural organization. Instead of starting on any day of the week the previous plan finished, it would always start on Monday. I also proposed to reduce the number of activities to 3 per day, show the necessary materials for the plan, and allow users to see previous plans."
                },
                {
                  type: 'text',
                  text: "About the assessment, I proposed asking the users at the end of each activity to assess that activity and allow them in the onboarding to skip the initial assessment."
                },
                {
                  type: 'text',
                  text: "Another update was in the progress section. We added two cards to track each baby's height and weight, with statistical information about the appropriate height and weight for their age."
                },
                {
                  type: 'text',
                  text: "The content team also designed a new section within the catalog with health content since it was most of the expert advice that users were looking for. They also wrote new articles focused on health, which were differentiated within the web app."
                },
                {
                  type: 'text',
                  text: "Finally, other points were to allow the registration of unborn babies, increase the app's content to cover up to 3 years of age and integrate more diverse illustrations to replace those that gave rise to racism and exclusion complaints."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu47'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu48'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu49'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu50'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu51'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu52'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu53'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu54'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu55'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu56'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu57'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu58'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu59'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu77'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu78'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu79'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu87'
                },
                {
                  type: 'subtitle',
                  subtitle: "Mobile"
                },
                {
                  type: 'text',
                  text: "As mentioned before, it was vital to prepare the new version of the web app to be packaged as a native Android app and cover that deficit. "
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu60'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu61'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu62'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu63'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu64'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu65'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu66'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu67'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu68'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu69'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu70'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu71'
                },
                {
                  type: 'subtitle',
                  subtitle: "User acquisition"
                },
                {
                  type: 'text',
                  text: "As mentioned, the web app was an essential means of acquiring users, especially those coming through FB's campaigns. In conjunction with the PO and the CMO, we sought to leverage FB's API to improve user acquisition. Among the measures we took, the following stand out:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Registration with Facebook.",
                    "Unlocking content by sharing a post about Kinedu on Facebook (Kinedu had a freemium business model, allowing users to view limited content unless they subscribed or purchased the content individually).",
                    "Discounts on subscriptions for sharing one or more Facebook posts about Kinedu.",
                    "Functionality to share app content, especially about each baby's progress."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu72'
                },
                {
                  type: 'text',
                  text: "We also added the following changes to improve the subscription and payment experience:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "A section dedicated to subscriptions with deep linking.",
                    "A section dedicated to canceling subscriptions with deep linking.",
                    "Iconography that would give security to the user, such as locks, logos of regulatory entities, etc. (for this point and in general for the whole re-design of the app, I consulted some research on trust in software, for this point especially in e-commerce, from which I obtained that the validation of third parties is a critical factor for the generation of trust in a payment process).",
                    "Implementation of security measures, from the most basic such as SSH certificates, which the web app lacked."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu75'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu76'
                },
                {
                  type: 'text',
                  text: "Because the improvements in payments and subscriptions were quite effective, in the following iterations, together with the other product designer, the PO, and the CMO, we included a gift card section to redirect there to all who wanted to acquire one. We even expected to turn the web app into the main subscription channel. With this, we could charge less to users and obtain more income by not paying the 30% App Store fee."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu80'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu82'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu83'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu84'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu85'
                },
                {
                  type: 'text',
                  text: "Eventually, it also became the main channel for creating promotions."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu86'
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu88'
                },
                {
                  type: 'subtitle',
                  subtitle: "Partnerships"
                },
                {
                  type: 'text',
                  text: "Given the speed of development and publication of the web app, besides being the testing laboratory, it also became a strategic tool for collaborations with various associations and companies. The primary partnerships were the following:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Stanford University.",
                    "Early Childhood Business Accelerator of Universidad Regiomontana.",
                    "The municipal government of Escobedo in the state of Nuevo Leon, Mexico.",
                    "Mead Johnson.",
                    "Nestle."
                  ]
                },
                {
                  type: 'text',
                  text: "While the first two served mostly for scientific research and evaluation and algorithm improvement, the last two involved creating unique versions of the web app for each collaboration and left important lessons in marketing and user acquisition through those two companies."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu89'
                },
                {
                  type: 'text',
                  text: "I would especially like to highlight the collaboration with the municipal government of Escobedo as it involves all kinds of challenges. The project consisted of an early stimulation program with 30 low-income families. The government acted as the organizer and Kinedu as the content provider. In the beginning, in addition to the natural challenges of organizing the families, there was a critical technical challenge: The families did not have a device to use Kinedu."
                },
                {
                  type: 'text',
                  text: "In that first problem, the government provided 30 tablets for the families to use the web app. Still, immediately another technical problem arose: the families did not have an internet connection in their homes. That last problem came to light during the first meeting I attended, in which we explained the project to the families and how to use the app. The solution was to give them a cell data plan."
                },
                {
                  type: 'text',
                  text: "Both solutions (tablet and data plan) involved their problems, including using the tablet and the internet for other purposes instead of using Kinedu or even selling the devices."
                },
                {
                  type: 'text',
                  text: "Finally, a technical problem that had to be solved exclusively by me was how heavy the site was, the cellular network in the area was quite precarious, and the data plans in Mexico were (and still are) limited, so I had to pay special attention to the performance of the web app, reducing calls to the API and avoiding loading any unnecessary content."
                },
                {
                  type: 'text',
                  text: "From that problem, it became clear to me how deeply inaccessible our platform was, how little inclusive. Let alone that our focus was the iOS app, which has a minimal market share in Mexico and other industrializing countries. As part of my design process, I was always concerned that it be accessible to people with visual or hearing disabilities and, in general, that it seeks the users' well-being and not cause them any physical or mental harm. Still, it became clear how limited my considerations for inclusion in my process were in that project. We couldn't consider Kinedu a welfare application; it was a profoundly exclusionary application, which claimed to be an affordable, quality option to face-to-face early stimulation programs, but it couldn't reach the bulk of the population that couldn't afford such programs. It was technologically exclusive and through the user interface design, primarily through its communication, which was very little explanatory, really cryptic. All the problems of inclusion of the interface came to light as the project progressed with those 30 families; I saw how the patterns that we as designers consider obvious failed, and how complicated people's environments can be when using an app; for example, we had activities that required various materials that families did not have in their homes or activities that were on the floor, but some families had a soil floor in their homes and could not perform those activities."
                },
                {
                  type: 'text',
                  text: "We started this project to positively impact these families' lives, leaving them with the necessary knowledge for their children's development, but we learned the most significant lessons. With this, I started to create an app with better performance and more explanation, which would be more concerned about people's context."
                },
                {
                  type: 'subtitle',
                  subtitle: "Context"
                },
                {
                  type: 'text',
                  text: "Looking for more information about the context, we improved our research process. Still, I also proposed to create a dynamic context-aware application that would take into account the variables we could get from the users, the tasks they performed, and the environment in which they performed them."
                },
                {
                  type: 'text',
                  text: "I considered three primary sources of information to cross-check variables and give a contextual experience to users:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Information that we could extract from the device or browser.",
                    "Third-party APIs.",
                    "Information about the user's profile, activity catalog, and tracking tools."
                  ]
                },
                {
                  type: 'text',
                  text: "By crossing the variables within those three blocks, I implemented an assistant that had two main functions:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "Appear when a strange user behavior suggested some interaction problem.",
                    "Suggest activities suitable for the inferred context."
                  ]
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu91'
                },
                {
                  type: 'text',
                  text: "It was especially valuable to cross the variables to suggest suitable activities since it could be integrated into the activity plan's algorithm and improve its performance. Since the activities were linked to materials and places in the house, certain factors caused the plan's activities to be unsuitable. For example, if we were in winter and the activity plan included numerous outdoor activities, the baby lost a week of progress."
                },
                {
                  type: 'diagram',
                  diagram: 'kinedu92'
                }
              ]
            },
            {
              title: 'Results',
              content: [
                {
                  type: 'text',
                  text: "In a year, the web app went from being a forgotten cargo to a critical piece in the Kinedu ecosystem. Some of its results were:"
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    "It became a vital tool in collaborations with companies, universities, and organizations.",
                    "It became the reference point for gift cards and subscriptions and an essential income source that did not receive the App Store's cut.",
                    "It gave Android users an experience that matched the iOS app.",
                    "Within Kinedu, it became a benchmark for information gathering, testing, informed design, and experimentation."
                  ]
                },
                {
                  type: 'text',
                  text: "It's worth noting that with all of this, it earned an essential place within Kinedu, it became a priority, so that when I left the company, new developers and designers joined in, focused on the web app and its evolution, having after two years (since I joined Kinedu) a dedicated team that continues to make it grow quickly and adequately."
                },
                {
                  type: 'text',
                  text: "PS. As a collateral benefit, it validated the Android app's demand, which was later developed as a native application."
                }
              ]
            }
          ]
        },
        {
          id: 'oklex',
          title: 'Oklex web and mobile app design',
          thumbnail: 'oklex-thumbnail',
          hero: 'oklex-hero',
          date: '2015',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'web',
              url: ''
            }
          ],
          company: {
            name: 'Oklex',
            url: 'http://oklex.net'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: 'What was Oklex?',
              content: [
                {
                  type: "text",
                  text: "It was a business process management software with two main components, a mobile app to design workflows and a web app to interact with those workflows."
                }
              ]
            },
            {
              title: 'Who developed it?',
              content: [
                {
                  type: "text",
                  text: "The application was developed by a small homonymous startup consisting of 4 founders (who had extensive experience on BPM software and had already created Oklex for desktop years ago), a back-end and front-end developer, and me, a product designer and mobile developer."
                },
                {
                  type: 'diagram',
                  diagram: 'oklex1'
                }
              ]
            },
            {
              title: 'Who was it aimed at?',
              content: [
                {
                  type: "text",
                  text: "The application was B2B; it was aimed at micro, small and medium companies established in Mexico, which, for various reasons, did not have any of the products already established."
                },
                {
                  type: "text",
                  text: "It should be noted that micro, small, and medium companies represent 95% of the country's total number."
                },
                {
                  type: 'diagram',
                  diagram: 'oklex2'
                },
                {
                  type: "text",
                  text: "Among them, the distribution is as follows:"
                },
                {
                  type: 'diagram',
                  diagram: 'oklex3'
                },
                {
                  type: "text",
                  text: "Oklex users were the employees of these companies, having a good number of founders as users."
                },
                {
                  type: "text",
                  text: "Although the end-users' profile was quite broad, since they are practically the entire workforce of the country, it is noteworthy that whether they are qualified or unqualified employees, they usually use these types of companies as a platform to learn and look for a large company later."
                }
              ]
            },
            {
              title: 'What was my job?',
              content: [
                {
                  type: "subtitle",
                  subtitle: "Understanding"
                },
                {
                  type: "text",
                  text: "First, I spent a lot of time talking and interviewing the founders to understand the context in which Oklex would be inserted since they were users of BPM software in large companies and had already created the desktop product. "
                },
                {
                  type: 'diagram',
                  diagram: 'oklex4'
                },
                {
                  type: 'diagram',
                  diagram: 'oklex5'
                },
                {
                  type: 'diagram',
                  diagram: 'oklex6'
                },
                {
                  type: "text",
                  text: "It was especially relevant to know why the desktop version did not work and why they decided to make a new version. The following points stand out from the failure of the first version:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "The first application lacked focus.",
                    "Because it lacked focus, they tried to market it to all kinds of companies.",
                    "They were in direct competition with established technology giants.",
                    "Large companies did not trust software from a startup against software from established companies.",
                    "Large companies already had long-term contracts for business software.",
                    "Smaller companies did not see the need for the application.",
                    "Small businesses often did not have the resources to invest in one or more computers.",
                    "Small companies could not train their employees in computer use.",
                    "Although, in some respects, more straightforward than the competition, the first application had a long learning curve."
                  ]
                },
                {
                  type: "text",
                  text: "Of the reasons to try again, the following stand out:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "New focus on micro, small, and medium enterprises are the vast majority of the country.",
                    "A better understanding of these businesses' needs based on previous experience (Most argued that they did not need this type of software, nor as many components as the large suites offered, nor did they have the resources to acquire it).",
                    "Technological democratization through the expansion of cell phones, tablets, and low-cost laptops.",
                    "Better government support for these types of businesses (mostly loans)."
                  ]
                },
                {
                  type: "text",
                  text: "As a next step, I talked to and interviewed potential customers and users, including some Oklex for desktop users, to better understand their needs and reasons for using this software type. From these sessions, the following needs stand out:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "Define processes and have visibility over them.",
                    "Centralize information.",
                    "Centralize administrative processes such as inventory, purchase/sale, payroll.",
                    "Do not acquire more than one tool.",
                    "Given the team's small size, reduce the workload of employees, who usually must exercise several roles and can not focus on one task at a time."
                  ]
                },
                {
                  type: "text",
                  text: "I also highlight the fear of potential customers and users to acquire and use business software, as they said they were too complicated. By doing a benchmark and showing the options available in the interviews, I validated the strong rejection they had towards this type of software, even when I mentioned that these systems covered all the needs they had expressed to me."
                },
                {
                  type: "text",
                  text: "Finally, I performed desktop research in which I could obtain some demographic and market data, among which are those shown in sections above. "
                },
                {
                  type: "text",
                  text: "Technology adoption was cited as one of the most significant barriers to the survival of small companies, which have a mortality rate of 80% in the first three years, so the adoption of software as BPM was a proven need but ignored by entrepreneurs."
                },
                {
                  type: "text",
                  text: "But although technology adoption was a problem for companies, in the population panorama, things were different since, at that time (2015), more than 65% of the Mexican population already had access to cell phones and the internet and had already become accustomed to the use of all types of applications (mainly social networks: Facebook, WhatsApp, Youtube, Instagram, Twitter) which could be useful for Oklex in helping to reduce the learning curve of a new app. I also highlight the documented tendency of small companies' employees to learn new tools, as these companies are often used as a platform for learning."
                },
                {
                  type: "text",
                  text: "Regarding the platforms used by the Mexican population:"
                },
                {
                  type: "bullet-list",
                  bullets: [
                    "86% of Mexicans access the internet through an Android or iOS device.",
                    "The market share of Android vs. iOS is 79% vs. 21%, respectively.",
                    "The market share of Android devices is dominated by Samsung and Motorola, with 35.9% and 14.2%, respectively."
                  ]
                },
                {
                  type: "diagram",
                  diagram: "oklex7"
                },
                {
                  type: "text",
                  text: "But although Android was largely dominating the consumer market, iOS dominated the corporate market, even in small companies. In fact, in conversations with potential customers, all stated that if they were to purchase a device to use a process automation app, they would choose an iPad."
                },
                {
                  type: "subtitle",
                  subtitle: "Building"
                },
                {
                  type: "text",
                  text: "The functions to be included (defining a process, having visibility of it, and automating them to 'run' it and save employees time) represents only two-thirds of the flow and elements needed for process management. As all processing requires inputs, processes, and outputs, the second and third ones are already considered, and the first ones are missing."
                },
                {
                  type: "text",
                  text: "Besides being necessary for creating processes, the inputs meet the needs of centralizing information, and together with the other factors, consolidate processes and do not require more tools. Also, if they were loaded only once and kept updated, the final experience was improved by not manually filling each time the user used the tool."
                },
                {
                  type: "text",
                  text: "The necessary inputs were: company, schedules, roles, users, and forms."
                },
                {
                  type: "diagram",
                  diagram: "oklex8"
                },
                {
                  type: "text",
                  text: "It was also of utmost importance that all inputs were reusable information components to be used together ultimately, and within themselves, in processes and workflows."
                },
                {
                  type: "diagram",
                  diagram: "oklex9"
                },
                {
                  type: "text",
                  text: "In short, the application had to allow the loading of data from the company to use it to design workflows that could then be automated and executed."
                },
                {
                  type: "text",
                  text: "It should be clarified that although the process was sequential, it was not linear. Therefore, it should be possible to visit each step of the process without going through the previous or the next one immediately. "
                },
                {
                  type: "diagram",
                  diagram: "oklex10"
                },
                {
                  type: "text",
                  text: "Taking into account all the factors mentioned above, the sections, flow, and structure of the application were as follows:"
                },
                {
                  type: "diagram",
                  diagram: "oklex11"
                },
                {
                  type: "text",
                  text: "This was a considerable improvement to the flow of the original desktop app:"
                },
                {
                  type: "diagram",
                  diagram: "oklex12"
                },
                {
                  type: "text",
                  text: "As represented in the diagrams, the application's use would be sequential but not linear, so I added each of the categories of inputs in the same hierarchy within the main navigation. These categories were all indexes that gave access to detailed views of the elements they contained. These detailed views allowed consultation, editing, and removal of elements."
                },
                {
                  type: "diagram",
                  diagram: "oklex13"
                },
                {
                  type: "text",
                  text: "Since the navigation consisted of index categories and detailed views, I designed a screen with a reusable structure for the index view and another for the detailed views, which were reused at the design level and the code level. The reuse of elements allowed to accelerate the design, development, and testing process and reduce users' learning curve. If they knew how to use one screen, they knew how to use the rest."
                },
                {
                  type: "text",
                  text: "The reusable index screen consisted of a list and a grid. The list was used for all input index screens and the grid only for the workflow index screen because the thumbnail was allowed to preview the flow."
                },
                {
                  type: "diagram",
                  diagram: "oklex14"
                },
                {
                  type: "text",
                  text: "The reusable detail view screen consisted of one or more form cards and a designer, used only to view and edit the workflows."
                },
                {
                  type: "diagram",
                  diagram: "oklex15"
                },
                {
                  type: "text",
                  text: "From the beginning, I considered the reusable architecture of the app, even in the smallest components."
                },
                {
                  type: "diagram",
                  diagram: "oklex16"
                },
                {
                  type: "diagram",
                  diagram: "oklex17"
                },
                {
                  type: "text",
                  text: "For the design canvas, it is worth noting that a sample of potential users was interviewed about flow design apps they had used. Most argued that they had not used any. Instead, they resorted to analogous methods such as whiteboards or paper, and in case of using an app, they used PowerPoint on the desktop or drawing apps on tablets or phones."
                },
                {
                  type: "text",
                  text: "Regarding the graphical interface and interaction patterns, it is essential to note that, as stated before, most of the devices would be iOS. Still, most of the users would be Android users. So we decided to start developing the app for iOS but taking into account an interface design that follows the patterns of Android and uses the interaction patterns that users had already learned from apps like WhatsApp and thus reduce the learning curve."
                },
                {
                  type: "diagram",
                  diagram: "oklex18"
                },
                {
                  type: "diagram",
                  diagram: "oklex19"
                },
                {
                  type: "diagram",
                  diagram: "oklex20"
                },
                {
                  type: "diagram",
                  diagram: "oklex21"
                },
                {
                  type: "diagram",
                  diagram: "oklex22"
                },
                {
                  type: "diagram",
                  diagram: "oklex23"
                },
                {
                  type: "diagram",
                  diagram: "oklex24"
                },
                {
                  type: "diagram",
                  diagram: "oklex25"
                },
                {
                  type: "diagram",
                  diagram: "oklex26"
                },
                {
                  type: "diagram",
                  diagram: "oklex27"
                },
                {
                  type: "diagram",
                  diagram: "oklex28"
                },
                {
                  type: "diagram",
                  diagram: "oklex29"
                },
                {
                  type: "subtitle",
                  subtitle: "Testing"
                },
                {
                  type: "text",
                  text: "The tests with the wireframes did not show any significant difficulty in their use, users quickly understood the patterns, and the Canvas was unanimously qualified as simple to use."
                },
                {
                  type: "text",
                  text: "About the mockups, I followed the wireframes path using Android patterns and styles, as already said, due to the dominance of Android in the Mexican market. "
                },
                {
                  type: "text",
                  text: "A few users highlighted interaction problems with the tests. For example, the FAB button was not well understood, especially by maturer users. And a few users argued that they wanted more explanation in each section, including videos explaining how to use the tool and what could be done with it."
                },
                {
                  type: "text",
                  text: "The canvas did not represent any interaction problem, it was clear that elements were clickable, and the interaction of dragging was clear from the first moment."
                },
                {
                  type: "text",
                  text: "Emotionally I sought to distance Oklex from competitors by using color in line with non-business applications that users were already familiar with and enjoyed using."
                },
                {
                  type: "diagram",
                  diagram: "oklex30"
                },
                {
                  type: "diagram",
                  diagram: "oklex31"
                },
                {
                  type: "diagram",
                  diagram: "oklex32"
                },
                {
                  type: "diagram",
                  diagram: "oklex33"
                },
                {
                  type: "diagram",
                  diagram: "oklex34"
                },
                {
                  type: "diagram",
                  diagram: "oklex35"
                },
                {
                  type: "diagram",
                  diagram: "oklex36"
                },
                {
                  type: "diagram",
                  diagram: "oklex37"
                },
                {
                  type: "diagram",
                  diagram: "oklex38"
                },
                {
                  type: "diagram",
                  diagram: "oklex39"
                },
                {
                  type: "diagram",
                  diagram: "oklex40"
                },
                {
                  type: "diagram",
                  diagram: "oklex41"
                }
              ]
            },
            {
              title: 'Results',
              content: [
                {
                  type: 'text',
                  text: "At the end of the first round of design and development of the app, it was well-received in tests and began to be commercialized."
                }
              ]
            }
          ]
        },
        {
          id: 'duparc',
          title: 'DUPARC',
          thumbnail: 'duparc-thumbnail',
          hero: 'duparc-hero',
          date: '2016',
          role: 'Design & development',
          platforms: [
            {
              name: 'Web',
              url: 'https://duparcrealty.com'
            }
          ],
          company: {
            name: 'DUPARC',
            url: 'https://duparcrealty.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "DUPARC is a real estate agency focused in the Riviera Maya area in the south of Mexico, they offer their services through their web application where people can review their properties and contact them."
                }
              ]
            },
            {
              title: "Team",
              content: [
                {
                  type: 'text',
                  text: "This project was done by 3 people, an intermediary who was in charge of negotiations and raising requirements, a back-end developer and me working as a front-end developer and product designer."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I was in charge of working mainly on the product interface as the requirements came through the intermediary, who also presented the interface design iterations to the client. I also developed the web application and oversaw the development of the API."
                },
                {
                  type: 'text',
                  text: "We also developed a CMS so that the client could manage the content of the app on their own."
                },
                {
                  type: 'text',
                  text: "For this project we used the MEAN Stack."
                },
                {
                  type: 'diagram',
                  diagram: "duparc1"
                },
                {
                  type: 'diagram',
                  diagram: "duparc2"
                },
                {
                  type: 'diagram',
                  diagram: "duparc3"
                },
                {
                  type: 'diagram',
                  diagram: "duparc4"
                },
                {
                  type: 'diagram',
                  diagram: "duparc5"
                },
                {
                  type: 'diagram',
                  diagram: "duparc6"
                }
              ]
            }
          ]
        },
        {
          id: 'rss',
          title: 'RS Services',
          thumbnail: 'rss-thumbnail',
          hero: 'rss-hero',
          date: '2016',
          role: 'Design & development',
          platforms: [
            {
              name: 'Android',
              url: ''
            },
            {
              name: 'web',
              url: ''
            }
          ],
          company: {
            name: 'RSS',
            url: ''
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "RSS was a company that offered a subscription to a wide range of services, from medical to legal care. For them I developed a platform with two components, a web app for administrators and business owners and an Android app for administrators."
                }
              ]
            },
            {
              title: "Team",
              content: [
                {
                  type: 'text',
                  text: "The team consisted of a back-end developer, an Android developer and me as a front-end developer and designer."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed both applications and led two other developers, a back-end developer and an Android developer. I also did the development of the web client. On the technological side we used PostgreSQL, RoR and Angular."
                },
                {
                  type: 'diagram',
                  diagram: "rss1"
                },
                {
                  type: 'diagram',
                  diagram: "rss2"
                },
                {
                  type: 'diagram',
                  diagram: "rss3"
                },
                {
                  type: 'diagram',
                  diagram: "rss4"
                }
              ]
            }
          ]
        },
        {
          id: 'wegoo',
          title: 'Wegoo',
          thumbnail: 'wegoo-thumbnail',
          hero: 'wegoo-hero',
          date: '2015',
          role: 'Development',
          platforms: [
            {
              name: 'Web',
              url: ''
            }
          ],
          company: {
            name: 'Wegoo',
            url: ''
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "Wegoo was a digital education platform for elementary schools that was based on the PISA program and the Mexican and US curricula to integrate interactive activities to encourage children's curiosity in science. "
                }
              ]
            },
            {
              title: "Team",
              content: [
                {
                  type: 'text',
                  text: "The team was integrated by the two founders, one of them in charge of the product design and a couple of developers including me."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I worked hand in hand with the founders to create the product from scratch, during the first weeks I was in charge of raising the requirements and begin the development of the API and the web client, but soon after, I made the decision to integrate one more person to the team to whom I delegated the development of the API and I focused fully on the development of the client. For this project we used the MEAN Stack."
                },
                {
                  type: 'diagram',
                  diagram: "wegoo1"
                },
                {
                  type: 'diagram',
                  diagram: "wegoo2"
                },
                {
                  type: 'diagram',
                  diagram: "wegoo3"
                }
              ]
            },
            {
              title: "Results",
              content: [
                {
                  type: 'text',
                  text: "In less than 6 months we developed the complete product and started testing it in 10 schools. Shortly after the testing began, a larger company bought the startup and the founders joined that company."
                }
              ]
            }
          ]
        },
        {
          id: 'appstores',
          title: 'App stores',
          thumbnail: 'appstores-thumbnail',
          hero: 'appstores-hero',
          date: '2015',
          role: 'Development',
          platforms: [
            {
              name: 'Web',
              url: ''
            }
          ],
          company: {
            name: 'Naranya',
            url: 'https://www.naranya.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "NMarket is an Android app store created to be integrated into devices for emerging markets in partnership with manufacturers and telcos. From these alliances emerged spin-offs such as Claro apps and Tigo marketplace. It is worth noting that its main attraction was its payment engine, which charged purchases to the cellular account, thus attacking a large unbanked market."
                }
              ]
            },
            {
              title: "Team",
              content: [
                {
                  type: 'text',
                  text: "The team consisted of 6 developers, 2 product designers and a manager. I was part of the development team as a front-end developer."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I worked with another front-end developer creating the client (using Ember) and the store generator. We created a generator using Grunt that required only entering a series of variables to generate new web stores for the manufacturer and telco alliances. Once the variables were entered it generated a store with a variant of layout and style according to the requested brand."
                },
                {
                  type: 'text',
                  text: "In addition we made customized adjustments to each store when necessary, for example in Claro apps, we added subscription to video games, with that subscription, users could download and play unlimited games within the selected catalog."
                },
                {
                  type: 'text',
                  text: "We also developed all the tools for developers to upload their applications to the stores and inject their apps when necessary, as in the case of Claro."
                },
                {
                  type: 'text',
                  text: "I also conducted interviews with potential users during the first stage of the project, especially to test the adoption of the payment engine, which risked being linked to people's perception about telcos."
                },
                {
                  type: 'diagram',
                  diagram: "appstores1"
                },
                {
                  type: 'diagram',
                  diagram: "appstores2"
                },
                {
                  type: 'diagram',
                  diagram: "appstores3"
                },
                {
                  type: 'diagram',
                  diagram: "appstores4"
                },
                {
                  type: 'diagram',
                  diagram: "appstores5"
                },
                {
                  type: 'diagram',
                  diagram: "appstores6"
                },
                {
                  type: 'diagram',
                  diagram: "appstores7"
                },
                {
                  type: 'diagram',
                  diagram: "appstores8"
                },
                {
                  type: 'diagram',
                  diagram: "appstores9"
                }
              ]
            },
            {
              title: "Results",
              content: [
                {
                  type: 'text',
                  text: "After six months of development, our platform won the tenders of 3 telcos and one manufacturer. So we started to deploy with the brand of these companies. In less than a year the set of stores reached an audience of more than 300 million users."
                }
              ]
            }
          ]
        },
        {
          id: 'ligamania',
          title: 'Ligamanía',
          thumbnail: 'ligamania-thumbnail',
          hero: 'ligamania-hero',
          date: '2014',
          role: 'Development',
          platforms: [
            {
              name: 'Web',
              url: ''
            }
          ],
          company: {
            name: 'Naranya',
            url: 'https://www.naranya.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "Ligamanía was a fantastic soccer web app, with a focus on the Brazil 2014 World Cup. In the app users could build their team with the players that would play on that day. Ligamanía was updated in real time receiving data of every move that each player made in the match, so it could also be used to follow the matches."
                }
              ]
            },
            {
              title: "Team",
              content: [
                {
                  type: 'text',
                  text: "The team consisted of 4 people, 1 hustler, 1 designer, 1 back-end developer and me as front-end developer."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "We started the project a month before the start of the World Cup, so we followed the Design Sprint to meet the date. That way also the whole team was able to contribute to the design and functionality of the app."
                },
                {
                  type: 'text',
                  text: "I was mainly in charge of working on the development of the web client, which I developed using Angular JS."
                },
                {
                  type: 'text',
                  text: "One of the main problems at the time of creating the product was that the Mexican legislation and the rules of the app store considered that this type of games were games of chance, that is why we could not create a native app at the time."
                },
                {
                  type: 'diagram',
                  diagram: "ligamania1"
                },
                {
                  type: 'diagram',
                  diagram: "ligamania2"
                },
                {
                  type: 'diagram',
                  diagram: "ligamania3"
                }
              ]
            },
            {
              title: "Results",
              content: [
                {
                  type: 'text',
                  text: "The application was completed in time for the World Cup. Some groups were made with prizes sponsored by the company and we could see in its first stage of use the attractiveness of the results in real time and how several people used the app to follow the results of the World Cup as we got the results seconds before the TV stations. "
                },
                {
                  type: 'text',
                  text: "On the other hand I released the libraries that I created for this project, especially the components that I made using the Material Design style and the integration with the Angular Material library using Bower."
                }
              ]
            }
          ]
        },
        {
          id: 'landings',
          title: 'Landing pages',
          thumbnail: 'landings-thumbnail',
          hero: 'landings-hero',
          date: '2014-2016',
          role: 'Design & development',
          platforms: [
            {
              name: 'Web',
              url: ''
            }
          ],
          company: {
            name: 'Softmob, ROMSEGA, Aprenda & Promar',
            url: ''
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "These are some of the landing pages I designed and developed between 2014 and 2016 for different clients in different fields, such as technology, construction, education, business consulting, etc."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I was the only designer and developer of these landing pages, except for the amiibos one, which I developed together with a friend who was in charge of making the scrapper that fed it. All of them were developed using the MEAN stack."
                },
                {
                  type: 'diagram',
                  diagram: "landings1"
                },
                {
                  type: 'diagram',
                  diagram: "landings2"
                },
                {
                  type: 'diagram',
                  diagram: "landings3"
                },
                {
                  type: 'diagram',
                  diagram: "landings4"
                },
                {
                  type: 'diagram',
                  diagram: "landings5"
                },
                {
                  type: 'diagram',
                  diagram: "landings6"
                },
                {
                  type: 'diagram',
                  diagram: "landings7"
                },
                {
                  type: 'diagram',
                  diagram: "landings8"
                },
                {
                  type: 'diagram',
                  diagram: "landings9"
                },
                {
                  type: 'diagram',
                  diagram: "landings10"
                },
                {
                  type: 'diagram',
                  diagram: "landings11"
                }
              ]
            }
          ]
        },
        {
          id: 'matchideas',
          title: 'Match Ideas',
          thumbnail: 'matchideas-thumbnail',
          hero: 'matchideas-hero',
          date: '2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: 'Match Ideas',
            url: 'https://matchideas.mx'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "Match Ideas was an app that connected entrepreneurs with investors, the entrepreneur profile could publish their ideas for which the app assisted them in the development of their elevator pitch and received notifications of what was happening with their projects. The investor profile could enter and see the projects feed and contact the entrepreneurs to invest in their projects or support them. Entrepreneurs could also contact each other to collaborate on their projects."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I led a team of 7 people in the creation of the branding and other audiovisual materials for the app. I was in charge of the mobile app design and developed the iOS app, a colleague developed the Android app."
                },
                {
                  type: 'diagram',
                  diagram: "matchideas1"
                }
              ]
            }
          ]
        },
        {
          id: 'festivals',
          title: 'Festival apps',
          thumbnail: 'festivals-thumbnail',
          hero: 'festivals-hero',
          date: '2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: 'Hellow, RMFF & Match Ideas',
            url: ''
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "These are a set of applications and a landing page for music and film festivals. The festivals were Hellow Fest, Noise Fest, Riviera Maya Film Festival and Match Fest."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I was the sole designer and developer of these 4 applications, which I made for iOS. All the festivals already had a brand except Match, so in terms of design one of my main tasks was to adapt that brand properly to an app."
                },
                {
                  type: 'diagram',
                  diagram: "festivals1"
                },
                {
                  type: 'diagram',
                  diagram: "festivals2"
                },
                {
                  type: 'diagram',
                  diagram: "festivals3"
                },
                {
                  type: 'diagram',
                  diagram: "festivals4"
                },
                {
                  type: 'diagram',
                  diagram: "festivals5"
                },
                {
                  type: 'diagram',
                  diagram: "festivals6"
                },
                {
                  type: 'diagram',
                  diagram: "festivals7"
                }
              ]
            }
          ]
        },
        {
          id: 'hungerculture',
          title: 'Hunger Culture',
          thumbnail: 'hungerculture-thumbnail',
          hero: 'hungerculture-hero',
          date: '2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: 'Hunger Culture',
            url: 'http://hungerculture.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "The Hunger Culture app was a digital magazine in which people could read news, view nearby events and play multimedia content. The magazine is focused on music and gastronomy so the events and multimedia section was key to complement the news related to these topics."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed and programmed the prototype of this application and its API. This application was part of my strategy to get clients for a nascent web and mobile development agency. In this agency I leaded its digital transformation and a multidisciplinary team of 6 people."
                },
                {
                  type: 'diagram',
                  diagram: "hungerculture1"
                }
              ]
            }
          ]
        },
        {
          id: 'carza',
          title: 'Carza',
          thumbnail: 'carza-thumbnail',
          hero: 'carza-hero',
          date: '2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: 'Carza',
            url: 'https://www.carza.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "It was an app developed for a Mexican real estate company, where users could consult properties and contact the company."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed and programmed the prototype of this application and its API. This application was part of my strategy to get clients for a nascent web and mobile development agency. In this agency I leaded its digital transformation and a multidisciplinary team of 6 people."
                },
                {
                  type: 'diagram',
                  diagram: "carza1"
                }
              ]
            }
          ]
        },
        {
          id: 'amfm',
          title: 'AMFM',
          thumbnail: 'amfm-thumbnail',
          hero: 'amfm-hero',
          date: '2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: 'AMFM',
            url: 'https://amfmstudios.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "AMFM Studios was an application created to showcase the content produced by the studio (news, movies and music), which is an Austin based family-operated production company that was founded in 2002 with strong ties to the live music scene."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed and programmed the prototype of this application and its API. This application was part of my strategy to get clients for a nascent web and mobile development agency. In this agency I leaded its digital transformation and a multidisciplinary team of 6 people."
                },
                {
                  type: 'diagram',
                  diagram: "amfm1"
                },
                {
                  type: 'diagram',
                  diagram: "amfm2"
                }
              ]
            }
          ]
        },
        {
          id: 'cheekd',
          title: "Cheek'd",
          thumbnail: 'cheekd-thumbnail',
          hero: 'cheekd-hero',
          date: '2012-2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: 'https://apps.apple.com/us/app/cheekd-online-dating/id807619613?ls=1'
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: "Cheek'd",
            url: 'https://www.cheekd.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "Cheek'd is an online dating app, when I worked on it, it used physical business cards, which cost $10 a month, which contained a phrase and a code that when entered into the app led to the card owner's profile."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed this iOS app based on a patent provided by the client. Together with a colleague we developed the API and the app."
                },
                {
                  type: 'diagram',
                  diagram: "cheekd1"
                }
              ]
            },
            {
              title: "Results",
              content: [
                {
                  type: 'text',
                  text: "We finished the app in three months and it was presented at SXSW 2013 where it was well received. Later, in February 2014, it was presented at Shark Tank where it was smashed by the 5 sharks. After knocking on many doors, its founder was able to get investment and to date the app is still afloat, abandoning the focus on business cards and instead focusing on geofencing."
                }
              ]
            }
          ]
        },
        {
          id: 'eventi',
          title: 'Eventi',
          thumbnail: 'eventi-thumbnail',
          hero: 'eventi-hero',
          date: '2012-2013',
          role: 'Design',
          platforms: [
            {
              name: 'iOS',
              url: ''
            },
            {
              name: 'Android',
              url: ''
            }
          ],
          company: {
            name: 'The Wagoo',
            url: 'https://www.thewagoo.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "Eventi was a professional social network that connected event attendees using beacons and geofencing."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed the app for Android and iOS based on two patents provided by the client (US8880101B2 and US9264874B2). The app was developed by one of my colleagues who integrated the LinkedIn and Meetup APIs to power the app."
                },
                {
                  type: 'diagram',
                  diagram: "eventi1"
                }
              ]
            },
            {
              title: "Results",
              content: [
                {
                  type: 'text',
                  text: "The app was completed in 3 months and was presented at SXSW 2013 where it was well received."
                }
              ]
            }
          ]
        },
        {
          id: 'tfbi',
          title: 'TFBI',
          thumbnail: 'tfbi-thumbnail',
          hero: 'tfbi-hero',
          date: '2012-2013',
          role: 'Design & development',
          platforms: [
            {
              name: 'iOS',
              url: ''
            }
          ],
          company: {
            name: 'TFBI',
            url: 'https://www.txfb-ins.com'
          },
          honours: [],
          press: [],
          sections: [
            {
              title: "Overview",
              content: [
                {
                  type: 'text',
                  text: "The Texas Farm Bureau Insurance Agent Memory Maker app was created specifically for agents to capture photographic memories of their clients and prospective clients at events sponsored and attended by Texas Farm Bureau Insurance."
                }
              ]
            },
            {
              title: "Work",
              content: [
                {
                  type: 'text',
                  text: "I designed the app for iPad and iPhone. Regarding the development, another developer did the iPad version and I did the iPhone version. An interesting challenge in this app were the limitations of the mobile internet in those years, not all agents had internet on their devices, not enough quality or intermittency; so I included a local database (SQLite) in which to store the photos and data to be sent once the phone was connected to a good internet connection."
                },
                {
                  type: 'diagram',
                  diagram: "tfbi1"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 'resources',
      title: 'Resources',
      type: 'projects',
      cta: '',
      message: {
        title: '',
        description: ''
      },
      projects: [
        {
          id: 'flowcharts',
          title: 'Flowcharts library',
          thumbnail: 'flowcharts-thumbnail',
          hero: 'flowcharts-hero',
          date: '2018',
          role: 'Design',
          platforms: [
            {
              name: 'Sketch',
              url: 'https://www.sketch.com'
            },
            {
              name: 'Figma',
              url: 'https://www.figma.com'
            }
          ],
          company: {
            name: 'Personal project',
            url: ''
          },
          honours: [],
          press: [],
          sections: [
            {
              title: 'Description',
              content: [
                {
                  type: 'text',
                  text: 'Flowcharts is a symbols library for creating flowcharts using Sketch and Figma. It consists of 97 symbols.'
                },
                {
                  type: 'subtitle',
                  subtitle: 'Symbols included'
                },
                {
                  type: 'bullet-list',
                  bullets: [
                    'Terminal',
                    'Process',
                    'Decision',
                    'Input/Output',
                    'Annotation',
                    'Predefined process',
                    'Database',
                    'Document',
                    'Documents',
                    'On-page connector',
                    'Off-page connector',
                    'True',
                    'False',
                    'Extract',
                    'Merge',
                    'Or',
                    'Summing',
                    'User',
                    '28 line types',
                    'Line bridge',
                    '2 endpoint types'
                  ]
                }
              ]
            },
            {
              title: 'Downloads',
              content: [
                {
                  type: 'actions',
                  actions: [
                    {name: 'Flowcharts for Sketch', type: 'external_url', content: 'https://arianmtz.com/assets/flowcharts.sketch'}, 
                    {name: 'Flowcharts for Figma', type: 'external_url', content: 'https://www.figma.com/file/DEXndcLJzmQRaJGm2w2G9P/flowcharts/duplicate'}
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 'contact',
      title: 'Contact',
      type: 'contact',
      cta: '',
      message: {
        title: '',
        description: ''
      },
      projects: []
    }
  ];

  getSections() {
    return this.sections;
  };

  getSection(sectionId: string) {
    return this.sections.find(x => x.id === sectionId);
  };

  getProject(sectionId: string, projectId: string) {
    var projects: Array<any> = this.sections.find(x => x.id === sectionId).projects;
    return projects.find(x => x.id === projectId);
  }
  
}
