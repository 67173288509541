import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Project } from '../project';
import { Section } from '../section';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navType: number;
  sections: object;
  currentUrl: string;
  currentSection: Section;
  currentProject: Project;

  constructor(private apiService: ApiService, private router: Router, private location: Location, private route: ActivatedRoute) {
    router.events.subscribe(event => {
      if (event.constructor.name === "NavigationEnd") {
        this.currentUrl = this.router.url;
        if (!router.url.search('/project/')) {
          this.navType = 1;
          this.currentSection = this.apiService.getSection(router.routerState.snapshot.root.firstChild.params.sectionId);
          this.currentProject = this.apiService.getProject(router.routerState.snapshot.root.firstChild.params.sectionId, router.routerState.snapshot.root.firstChild.params.projectId);
        } else if (!router.url.search('/projects/')) {
          this.navType = 2;
          this.currentSection = this.apiService.getSection(router.routerState.snapshot.root.firstChild.params.sectionId);
        } else {
          this.sections = this.apiService.getSections();
          this.navType = 0;
        }
      }
    });
  }

  ngOnInit() {

  }

  goBack() {
    this.location.back();
  }

}
