<div class="card" [routerLink]="['/project', sectionId, project.id]" fxFlex>
    <div class="content">
      <!-- <div fxLayout="column" fxLayoutAlign="center center" class="image" [ngStyle]="{'background-image':'radial-gradient('+project.color+' 2px, transparent 2px)', 'background-color':'white', 'background-size':'24px 24px' }">
        <img src="assets/{{project.title}}.png" alt="">
      </div> -->

      <img src="assets/{{project.thumbnail}}.png" alt="Illustration with isomorphic screenshots of the {{project.title}} project">
      <div class="text">
          <p class="title">{{project.title}}</p>
          <p class="description">
            <span *ngIf="project.date">{{project.date}}</span> 
            <span *ngIf="project.role">&nbsp;・&nbsp;{{project.role}}</span> 
            <span *ngIf="project.platforms">&nbsp;・&nbsp;</span>
            <span *ngFor="let platform of project.platforms; let first = first; let last = last">
              <span *ngIf="!first && !last">,&nbsp;</span><span *ngIf="last && !first">&nbsp;&&nbsp;</span>
              <span>{{platform.name}}</span>
            </span>
          </p>
          <!-- <p class="description">{{project.description}}</p> -->
      </div>
    </div>
    <div class="bisel"></div>
</div>