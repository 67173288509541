<div class="projects">
    <div class="hero" fxLayout="row" fxLayoutAlign="start center">
        <div>
          <!-- <p class="title">{{section.message.title}}</p> -->
          <p class="description">The projects included here span my entire career, but I exclude my current work at Oracle which includes product design, component design and creation of the Human-AI Interaction framework. I also do not include all those projects that I did under NDA or that unfortunately I lost all trace and proof of them, such as RATI 2017, Yapp News, Berel, NovaInk, J3, Promar, among others.</p>
        </div>
      </div>
    <div class="grid-container">
        <app-card *ngFor="let project of section.projects" [project]="project" [sectionId]="section.id"></app-card>
    </div>
</div>