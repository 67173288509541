<div class="project">
    <img src="assets/{{currentProject.hero}}.png" alt="Illustration with isomorphic screenshots of the {{currentProject.title}} project" class="hero-img">
    <section class="general-info">
        <div class="item">
          <p class="label">Client</p>
          <a href="{{currentProject.company.url}}" target="_blank" class="content" *ngIf="currentProject.company.url">{{currentProject.company.name}}</a>
          <p *ngIf="!currentProject.company.url" class="content">{{currentProject.company.name}}</p>
        </div>
        <div class="item">
          <p class="label">Year</p>
          <p class="content">{{currentProject.date}}</p>
        </div>
        <div class="item">
          <p class="label">Role</p>
          <p class="content">{{currentProject.role}}</p>
        </div>
        <div class="item">
          <p class="label">Platform<span *ngIf="currentProject.platforms.length > 1">s</span></p>
          <span *ngFor="let platform of currentProject.platforms; let first = first; let last = last" class="content">
            <span *ngIf="!first && !last">,&nbsp;</span><span *ngIf="last && !first">&nbsp;&&nbsp;</span>
            <span *ngIf="!platform.url">{{platform.name}}</span>
            <a href="{{platform.url}}" target="_blank" *ngIf="platform.url">{{platform.name}}</a>
          </span>
        </div>
    </section>
    <section *ngFor="let section of currentProject.sections">
      <h1 class="title">{{section.title}}</h1>
      <div *ngFor="let content of section.content">
        <!-- SUBTITLE -->
        <h2 *ngIf="content.type === 'subtitle'">{{content.subtitle}}</h2>
        <!-- THIRD-LEVEL TITLE -->
        <h3 *ngIf="content.type === 'third-level-title'">{{content.third_title}}</h3>
        <!-- TEXT -->
        <p *ngIf="content.type === 'text'">{{content.text}}</p>
        <!-- DIAGRAM -->
        <div *ngIf="content.type === 'diagram'"><img src="assets/{{content.diagram}}.png" alt="Diagram or screenshot of the {{currentProject.title}} project" class="diagram"></div>
        <!-- ACTIONS -->
        <div *ngIf="content.type === 'actions'" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start" class="actions">
          <app-button *ngFor="let action of content.actions" [name]="action.name" (click)="projectActions(action.type, action.content)"></app-button>
        </div>
        <!-- BULLET LIST -->
        <ul *ngIf="content.type === 'bullet-list'">
          <li *ngFor="let bullet of content.bullets">{{bullet}}</li>
        </ul>
        <!-- ALPHABET LIST -->
        <ul *ngIf="content.type === 'alphabet-list'" type="lower-alpha">
          <li *ngFor="let bullet of content.bullets">{{bullet}}</li>
        </ul>
         <!-- DECIMAL LIST -->
         <ul *ngIf="content.type === 'decimal-list'" type="decimal">
          <li *ngFor="let bullet of content.bullets">{{bullet}}</li>
        </ul>
      </div>
    </section>
  </div>