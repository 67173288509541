<section id="{{section.id}}">

  <h1 class="title" *ngIf="section.type !== 'hero'">{{section.title}}</h1>
  
  <div class="hero" fxLayout="row" fxLayoutAlign="start center" *ngIf="section.type === 'hero'">
    <div>
      <img src="assets/mid_arian.png" alt="Illustration of Arian standing" fxHide.xs>
    </div>
    <div>
      <p class="title">{{section.message.title}}</p>
      <p class="description">{{section.message.description}}</p>
      <div  class="see-more" *ngIf="section.cta" fxLayout="row">
        <app-button [name]="section.cta" [routerLink]="['/about']"></app-button>
      </div>
    </div>
  </div>

  <div class="grid-container" *ngIf="section.type === 'projects'">
      <app-card *ngFor="let project of section.projects | slice:0:4; let i=index" [project]="project" [sectionId]="section.id"></app-card>
  </div>

  <div  class="see-more" *ngIf="section.cta && section.type !== 'hero'">
      <app-button [name]="section.cta" [routerLink]="['/projects', section.id]"></app-button>
  </div>
  
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="section.type === 'contact'" class="contact">
    <!-- <img src="assets/avatar.svg" alt="" fxHide.xs class="avatar"> -->
    <div fxHide.xs><div class="avatar" alt="Avatar of Arian and Arian with a Blue Demon mask"></div></div>
    <p>If you want me to collaborate on a project with you or invite me to give a talk, just <a href="mailto:arianmtz@gmail.com?Subject=Hi" target="_top">send me an email</a> or a message on <a href="https://www.linkedin.com/in/arianmtz" target="_blank">LinkedIn</a>.</p>
  </div>

</section>